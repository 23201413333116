import React, { useState, useEffect, useRef } from 'react';
import {
  Header,
  Button,
  Modal,
  Icon,
  Grid,
  Label,
  Popup,
} from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';
import { useStitchAuth } from '../StitchAuth';
import { useStitchMembers } from '../StitchMembers';
import '../Common/Layout.css';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  excludedUrls,
  getPvShortUrl,
  getYM,
  notOnEmDate,
} from '../Common/analytics';
import { generateTodos } from '../Common/TodoUtils';
import { useGeneralParams } from '../GeneralParams';
import {
  activeMeds,
  activeMemos,
  age,
  dobStr,
} from '../Common/memberUtils';

const flash = keyframes`
  from {
    filter: brightness(135%) contrast(90%);
  }
  50% {
    filter: brightness(70%) contrast(150%);
  }
  to {
    filter: brightness(135%) contrast(90%);
  }
`;

const LabelWrapper = styled.div`
  padding-bottom: 1px;
`;

const CallButtonWrapper = styled.span`
  animation: ${flash}
    ${(props) => (props.ongoingCall ? `2.5s ease infinite` : '')};
`;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function HeaderSection(props) {
  const { ongoingCall, setIsCallingMember, setCallModalOpen } =
    useGeneralParams();
  const { userCustomData } = useStitchAuth();
  const { memberOne, memberOnePv, actions } = useStitchMembers();
  const location = useLocation();
  const [timeOffset, setTimeOffset] = useState(0);
  const [elapsed, setElapsed] = useState(0);
  const [timeAlertModal, setTimeAlertModal] = useState(false);
  const [orgViewers, setOrgViewers] = useState({});
  const history = useHistory();
  const ymRef = getYM();

  useEffect(() => {
    actions.getOrgViewers().then((res) => {
      setOrgViewers(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userCustomData) {
      actions.loadPageViews(props.memberId, ymRef, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, props.memberId, ymRef]);

  useEffect(() => {
    if (memberOnePv) {
      setElapsed(0); // reset elapsed;
    }
  }, [memberOnePv]);

  useEffect(() => {
    const includePv = (pv) => {
      const isDuplicate = pv.remove;
      const manualExclude = pv.exclude;
      const excludeUserTime =
        orgViewers[pv.userEmail]?.excludePatientTime;
      const excludedUrl =
        !pv.manualEntry &&
        excludedUrls.includes(getPvShortUrl(pv.url));
      const onEmDateExclude = !notOnEmDate(
        pv.start,
        memberOne.emDates,
      );

      if (
        isDuplicate ||
        manualExclude ||
        excludeUserTime ||
        excludedUrl ||
        onEmDateExclude
      ) {
        return false;
      }
      return true;
    };

    if (memberOne && memberOnePv && !_.isEmpty(orgViewers)) {
      // marking duplicates
      for (let i = 0; i < memberOnePv.length; i++) {
        for (let j = 0; j < i; j++) {
          if (
            memberOnePv[i].start === memberOnePv[j].start &&
            memberOnePv[i].end === memberOnePv[j].end
          ) {
            memberOnePv[i].remove = true;
          }
        }
      }

      let offset = _.reduce(
        memberOnePv.filter((x) => includePv(x)),
        (offset, pv) => {
          return offset + (pv.end - pv.start);
        },
        0,
      );
      setTimeOffset(offset);
    }
  }, [memberOne, memberOnePv, orgViewers]);

  useInterval(() => {
    if (elapsed > 0 && elapsed % (1000 * 60 * 10) === 0) {
      // every 10 minutes
      setTimeAlertModal(true);
    } else {
      setElapsed(elapsed + 1000);
      if (memberOne && elapsed > 0 && elapsed % 10000 === 0) {
        // every 10 seconds
        actions.updatePageViews(
          props.memberId,
          location.pathname,
          props.pvStart,
          props.pvStart + elapsed,
        );
        actions.updateTimeSpent(
          props.memberId,
          timeOffset + elapsed,
          ymRef,
        );
      }
    }
  }, 1000);

  let todos = [];
  const bI = memberOne?.billingInsights || {};
  // only generate todos if I have both billingInsights cycles.
  if (bI['99454'] && bI['99457']) {
    todos = generateTodos(memberOne, false, 3);
  }

  return (
    <div className="header-panel box">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            {memberOne && (
              <Header as="h4">
                <span className="grey text">
                  {(memberOne || {}).name}
                </span>{' '}
                &gt; {props.title}
                <Header.Subheader>
                  <small>
                    {memberOne.gender}, {age(memberOne.dob)} yrs old (
                    {dobStr(memberOne.dob)})
                  </small>
                </Header.Subheader>
              </Header>
            )}
          </Grid.Column>
          <Grid.Column textAlign="right" width={12}>
            {memberOne && (
              <>
                <Popup
                  trigger={
                    <Button
                      compact
                      circular
                      basic
                      size="mini"
                      onClick={() => {
                        history.push(
                          `/member-details/${props.memberId}/measurements`,
                        );
                      }}
                    >
                      <Icon name="weight" />
                      {memberOne.bodyWeight
                        ? memberOne.bodyWeight
                        : ' . '}
                    </Button>
                  }
                  position="bottom right"
                  content="Click to see the longitudinal data"
                />
                <Popup
                  trigger={
                    <Button
                      compact
                      circular
                      basic
                      size="mini"
                      onClick={() => {
                        history.push(
                          `/member-details/${props.memberId}/measurements`,
                        );
                      }}
                    >
                      <Icon name="heart" />
                      {memberOne.systolicBP
                        ? Math.round(memberOne.systolicBP)
                        : ' . '}
                      /
                      {memberOne.diastolicBP
                        ? Math.round(memberOne.diastolicBP)
                        : '.'}{' '}
                    </Button>
                  }
                  position="bottom right"
                  content="Click to see the longitudinal data"
                />
                <Popup
                  trigger={
                    <Button
                      compact
                      circular
                      basic
                      size="mini"
                      onClick={() => {
                        history.push(
                          `/member-details/${props.memberId}/measurements`,
                        );
                      }}
                    >
                      <Icon name="tint" />
                      {memberOne.bloodSugar
                        ? memberOne.bloodSugar
                        : ' . '}
                    </Button>
                  }
                  position="bottom right"
                  content="Click to see the longitudinal data"
                />
                {!excludedUrls.includes(props.url) &&
                  !userCustomData.excludePatientTime && (
                    <Popup
                      trigger={
                        <Button
                          compact
                          circular
                          basic
                          size="mini"
                          onClick={() => {
                            history.push(
                              `/member-details/${props.memberId}/manage-time`,
                            );
                          }}
                        >
                          <Icon name="clock" />
                          {new Date(elapsed + timeOffset)
                            .toISOString()
                            .substr(11, 8)}
                        </Button>
                      }
                      position="bottom right"
                      content="Click to see/manage the time spent this month"
                    />
                  )}
                <Popup
                  trigger={
                    <Button
                      compact
                      circular
                      basic={
                        activeMeds(memberOne.medications).length === 0
                      }
                      color="olive"
                      size="mini"
                      onClick={() => {
                        history.push(
                          `/member-details/${props.memberId}/manage-rx-info`,
                        );
                      }}
                    >
                      <Icon name="pills" />
                      Rx Info
                    </Button>
                  }
                  position="bottom right"
                  content={activeMeds(memberOne.medications).map(
                    (x, i) => (
                      <Label key={i} basic size="mini">
                        {x.drug_name}
                      </Label>
                    ),
                  )}
                />
                <Popup
                  wide="very"
                  hoverable
                  trigger={
                    <Button
                      style={{ cursor: 'default' }}
                      compact
                      circular
                      basic={
                        activeMemos(memberOne.noteData).length === 0
                      }
                      color="teal"
                      size="mini"
                    >
                      <Icon name="pin" />
                      Memos
                    </Button>
                  }
                  position="bottom right"
                  content={
                    activeMemos(memberOne.noteData).length === 0 ? (
                      'No memos'
                    ) : (
                      <>
                        {(activeMemos(memberOne.noteData) || []).map(
                          (med, i) => (
                            <LabelWrapper key={i}>
                              <Label size="large" color="teal" basic>
                                {med.value
                                  .replace(/<[^>]+>/g, '')
                                  .trim()}
                              </Label>
                            </LabelWrapper>
                          ),
                        )}
                      </>
                    )
                  }
                />
                <Popup
                  wide="very"
                  hoverable
                  trigger={
                    <Button
                      style={{ cursor: 'default' }}
                      compact
                      circular
                      basic={todos.length === 0}
                      color={
                        todos.length > 0 ? todos[0].color : 'grey'
                      }
                      size="mini"
                    >
                      <Icon name="check square outline" />
                      To-Do
                    </Button>
                  }
                  position="bottom right"
                  content={
                    todos.length === 0 ? (
                      'No To-dos'
                    ) : (
                      <>
                        {todos.map((todo, i) => (
                          <div
                            style={{ paddingBottom: '14px' }}
                            key={i}
                          >
                            <div
                              style={{
                                color: todo.color,
                                fontWeight: '600',
                              }}
                            >
                              {todo.title}
                            </div>{' '}
                            <div>{todo.description}</div>
                          </div>
                        ))}
                      </>
                    )
                  }
                />

                <Popup
                  trigger={
                    <CallButtonWrapper ongoingCall={ongoingCall}>
                      <Button
                        compact
                        circular
                        color="blue"
                        size="mini"
                        onClick={() => {
                          setIsCallingMember(true);
                          setCallModalOpen(true);
                        }}
                      >
                        <Icon name="phone" />
                        {ongoingCall ? 'Ongoing ' : ''}Call
                      </Button>
                    </CallButtonWrapper>
                  }
                  position="bottom right"
                  content={'Click to call the patient'}
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        basic
        onClose={() => setTimeAlertModal(false)}
        open={timeAlertModal}
        size="small"
      >
        <Modal.Header>
          <Icon name="clock" />
          Are you still reviewing this patient?
        </Modal.Header>
        <Modal.Content>
          <p>
            If you are still working on this patient, please click
            "Yes". While this pop-up is on, the time on this page is
            not counted.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setTimeAlertModal(false);
              history.push('/home');
            }}
          >
            <Icon name="home" /> No. Go to the patient list.
          </Button>
          <Button
            color="green"
            onClick={() => {
              setTimeAlertModal(false);
              setElapsed(elapsed + 1000);
            }}
          >
            <Icon name="play" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
