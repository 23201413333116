import React, { useState } from 'react';
import {
  Header,
  Grid,
  Button,
  Icon,
  Modal,
  Loader,
  Dimmer,
  Popup,
} from 'semantic-ui-react';
import { useStitchMembers } from '../StitchMembers';
import '../Common/Layout.css';
import { useHistory } from 'react-router-dom';
import { useGeneralParams } from '../GeneralParams';

export default function HeaderSection(props) {
  const { setIsCallingMember, setCallModalOpen } = useGeneralParams();
  const {
    prospectOne,
    actions: { moveProspectToRPM },
  } = useStitchMembers();
  const [loading, setLoading] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const history = useHistory();

  const handleEnroll = async () => {
    setLoading(true);
    setEnrolled(true);
    await moveProspectToRPM(prospectOne);
    setLoading(false);
  };

  return (
    <div className="header-panel box">
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      <Grid>
        <Grid.Row columns={2} style={{ paddingBottom: '0.5rem' }}>
          <Grid.Column>
            <Header as="h4">
              <span className="grey text">
                {(prospectOne || {}).name}
              </span>{' '}
              &gt; {props.title}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Popup
              trigger={
                <Button
                  compact
                  circular
                  color="blue"
                  size="mini"
                  onClick={() => {
                    setIsCallingMember(false);
                    setCallModalOpen(true);
                  }}
                >
                  <Icon name="phone" />
                  Call
                </Button>
              }
              position="bottom right"
              content={'Click to call the patient'}
            />
            <Popup
              trigger={
                <Button
                  compact
                  circular
                  primary
                  basic
                  size="mini"
                  onClick={() => handleEnroll()}
                >
                  <Icon name="play circle outline" />
                  Enroll
                </Button>
              }
              position="bottom right"
              content={'Click to enroll the patient into RPM'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        open={enrolled}
        onClose={() => {
          setEnrolled(false);
          history.push({
            pathname: '/prospects',
          });
        }}
        basic
        closeIcon
      >
        <Modal.Header>
          Congrats on enrolling an RPM member!
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Please don't forget to{' '}
              <mark>update the patient information!</mark>
            </p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
}
