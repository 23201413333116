import React, { useState } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { StitchMembersProvider } from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import { useHistory, useParams } from 'react-router-dom';
import TrackDeliveries from './TrackDeliveries';
import DeviceAssignment from './DeviceAssignment/DeviceAssignment';
import RequestDevices from './RequestDevices';

function HeaderView(props) {
  const viewMap = {
    fulfillment:
      'Device Fulfillment Insights - Weight Scale, BP Cuff, Glucometer',
    'device-assignment': 'Device Assignment',
    'request-devices': 'Request Devices',
  };

  return (
    <div className="header-panel box">
      <Header as="h4">
        {viewMap[props.view]}
        <Header.Subheader>
          <small>
            last updated at {new Date(props.pvStart).toLocaleString()}
          </small>
        </Header.Subheader>
      </Header>
    </div>
  );
}

export default function Insights() {
  const { viewDefault } = useParams();
  const [view, setView] = useState(viewDefault || 'fulfillment');
  let history = useHistory();
  const pvStart = new Date().getTime();

  const handleViewChange = (newView) => {
    history.push('/devices/' + newView);
    setView(newView);
  };

  return (
    <StitchMembersProvider>
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header
            as="h4"
            style={{ paddingLeft: '1em', color: '#fff' }}
          >
            Devices
          </Header>
          <Menu
            vertical
            secondary
            inverted
            style={{ background: 'transparent' }}
          >
            <Menu.Item
              active={view === 'fulfillment'}
              onClick={() => handleViewChange('fulfillment')}
            >
              #&nbsp;&nbsp;delivery-tracking
            </Menu.Item>
            <Menu.Item
              active={view === 'device-assignment'}
              onClick={() => handleViewChange('device-assignment')}
            >
              #&nbsp;&nbsp;device-assignment
            </Menu.Item>
            <Menu.Item
              active={view === 'request-devices'}
              onClick={() => handleViewChange('request-devices')}
            >
              #&nbsp;&nbsp;request-devices
            </Menu.Item>
          </Menu>
        </div>
        <HeaderView view={view} pvStart={pvStart} />
        {view === 'fulfillment' && <TrackDeliveries />}
        {view === 'device-assignment' && <DeviceAssignment />}
        {view === 'request-devices' && <RequestDevices />}
      </div>
    </StitchMembersProvider>
  );
}
