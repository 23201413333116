import React from 'react';
import { Link } from 'react-router-dom';
import {
  Checkbox,
  Table,
  Header,
  Label,
  Popup,
} from 'semantic-ui-react';
import styled from 'styled-components';
import Moment from 'react-moment';
import 'moment-timezone';
import NumberEasing from 'react-number-easing';
import { makeReadable } from '../../MemberDetails/ECGReports';
import { generateTodos } from '../../Common/TodoUtils';
import { truncateString } from '../../Common/utils';
import MeasureCell from './MeasureCell';
import { age } from '../../Common/memberUtils';

const LabelWrapper = styled.div`
  padding-bottom: 1px;
`;

const CheckboxWrapper = styled.div`
  padding-left: 4px;
`;

const LabelContents = styled.div`
  margin-top: -1px;
  margin-bottom: -1px;
`;

export default function TableRow({
  member,
  columns,
  userTimeZone,
  isMemberSelected,
  toggleMemberSelected,
}) {
  const profilePath = `/member-details/${member._id}/profile`;

  return (
    <Table.Row key={member._id}>
      <Table.Cell>
        <CheckboxWrapper>
          <Checkbox
            checked={isMemberSelected}
            onChange={() => {
              toggleMemberSelected();
            }}
          />
        </CheckboxWrapper>
      </Table.Cell>
      <Table.Cell>
        <Link to={profilePath}>
          <Header as="h5">
            <div>
              <div
                style={{
                  color: '#4986c6', // to make the name look like a link
                }}
              >
                {member.name}
              </div>
              <Header.Subheader>
                <span style={{ fontSize: '0.7em' }}>
                  {member.gender},&nbsp;
                  {age(member.dob)} years old
                </span>
              </Header.Subheader>
            </div>
          </Header>
        </Link>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Header as="h5">
          <NumberEasing
            value={member.measureFreq}
            decimals={2}
            speed={2000}
          />
          &nbsp;
          <span style={{ fontSize: '0.7em' }}>/day</span>
          <Header.Subheader>
            <span style={{ fontSize: '0.7em' }}>last 7 days</span>
          </Header.Subheader>
        </Header>
      </Table.Cell>
      {columns.bodyWeight && (
        <MeasureCell
          value={member.bodyWeight}
          diff={member.bodyWeightDiff}
          lastDT={member.bodyWeightLastDT}
          showDecimal={true}
          timeZone={userTimeZone}
        />
      )}
      {columns.systolicBP && (
        <MeasureCell
          value={member.systolicBP}
          value2={member.diastolicBP}
          diff={member.systolicBPDiff}
          diff2={member.diastolicBPDiff}
          lastDT={member.systolicBPLastDT}
          timeZone={userTimeZone}
        />
      )}
      {columns.pulse && (
        <MeasureCell
          value={member.pulse}
          diff={member.pulseDiff}
          lastDT={member.pulseLastDT}
          timeZone={userTimeZone}
        />
      )}
      {columns.ecg && (
        <Table.Cell textAlign="center">
          {member.ecg ? (
            <Header as="h5">
              <small>
                {member.ecg.value === 'sinus_rhythm' ||
                member.ecg.value === 'Normal' ? (
                  <span className="green text">
                    {' '}
                    {makeReadable(member.ecg.value)}
                  </span>
                ) : (
                  <span className="red text">
                    {' '}
                    {makeReadable(member.ecg.value)}
                  </span>
                )}
              </small>
              {false && <small>{member.ecg.bpm} bpm</small>}
              <Header.Subheader>
                <span style={{ fontSize: '0.7em' }}>
                  <Moment
                    format="M/D/YY H:mm"
                    tz={userTimeZone}
                    date={member.ecgLastDT}
                  />
                </span>
              </Header.Subheader>
            </Header>
          ) : (
            <span>.</span>
          )}
        </Table.Cell>
      )}
      {columns.bloodSugar && (
        <MeasureCell
          value={member.bloodSugar}
          diff={member.bloodSugarDiff}
          lastDT={member.bloodSugarLastDT}
          timeZone={userTimeZone}
        />
      )}
      {columns.step && (
        <MeasureCell
          value={member.step}
          diff={member.stepDiff}
          lastDT={member.stepLastDT}
          timeZone={userTimeZone}
        />
      )}
      {columns.spo2 && (
        <MeasureCell
          value={member.spo2}
          diff={member.spo2Diff}
          lastDT={member.spo2LastDT}
          timeZone={userTimeZone}
        />
      )}
      {columns.pef && (
        <MeasureCell
          value={member.pef}
          diff={member.pefDiff}
          lastDT={member.pefLastDT}
          timeZone={userTimeZone}
        />
      )}
      <Table.Cell textAlign="center">
        {generateTodos(member, true, 1).map((label, i) => (
          <LabelWrapper key={i}>
            <Popup
              hoverable
              trigger={
                <Link to={label.path} style={{ cursor: 'pointer' }}>
                  <Label
                    size="tiny"
                    basic={label.color !== 'red'}
                    color={label.color}
                  >
                    {label.color === 'red' ? (
                      label.title
                    ) : (
                      <LabelContents>{label.title}</LabelContents>
                    )}
                  </Label>
                </Link>
              }
              position="left center"
              content={label.description}
            />
          </LabelWrapper>
        ))}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {(member.activeMemos || []).map((x, i) => (
          <LabelWrapper key={i}>
            <Label size="tiny" color="teal" basic>
              <LabelContents>
                {truncateString(
                  x.value
                    .replace(/<[^>]+>/g, '')
                    .replace('&nbsp;', ' ')
                    .trim(),
                  50,
                )}
              </LabelContents>
            </Label>
          </LabelWrapper>
        ))}
      </Table.Cell>
    </Table.Row>
  );
}
