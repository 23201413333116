import 'moment-timezone';
import moment from 'moment-timezone';
import { getCurrentUser } from '../../realm/authentication';
import { dobStr } from './memberUtils';

// master list of urls that shouldn't count towards time. used in other parts of the app as well.
export const excludedUrls = ['em-visits', 'manage-time'];

export function getYM(x) {
  if (x) {
    return new Date(x).toISOString().slice(0, 7);
  } else {
    return new Date().toISOString().slice(0, 7);
  }
}

export function getYMD(x) {
  if (x) {
    return new Date(x).toISOString().slice(0, 10);
  } else {
    return new Date().toISOString().slice(0, 10);
  }
}

export const notOnEmDate = (time, emDates) => {
  let notCoinciding = true;
  const d = new Date(time);
  (emDates || []).forEach((em) => {
    const emd = new Date(em);
    if (
      d.getUTCDate() === emd.getUTCDate() &&
      d.getUTCMonth() === emd.getUTCMonth() &&
      d.getUTCFullYear() === emd.getUTCFullYear()
    ) {
      notCoinciding = false;
    }
  });
  return notCoinciding;
};

export const getPvShortUrl = (longUrl) => {
  const arr = longUrl.split('/');
  return arr[arr.length - 1];
};

// set the "current" billingInsights from live billing
// current billing insights means the billing insights whose cycle we are in at this moment in time.
// the current billing insights can be different for each code, since they have different cycles.
export const setCurrBillingInsights = (member, userTimeZone) => {
  const billingObjs = Object.values(member.liveBilling);
  const lastMonthRpmBI =
    billingObjs[billingObjs.length - 1].billingInsights;
  member.billingInsights = {
    99454: lastMonthRpmBI,
    99457: lastMonthRpmBI,
  }; //RPM

  const lastMonthCcmBI =
    billingObjs[billingObjs.length - 1].ccmBillingInsights || {};
  member.ccmBillingInsights = {
    99490: lastMonthCcmBI,
  }; // CCM

  // loop through each month's object to see which we are in.
  billingObjs.forEach((billingObject) => {
    const bI = billingObject.billingInsights;
    const ccmBI = billingObject?.ccmBillingInsights || {};
    const now = moment().tz(userTimeZone).format('YYYY-MM-DD');

    // 99454 fromDate includes first day of cycle, but toDate is actually the date right after the cycle finishes, so the toDate is not included in the cycle.
    // NOTE: if this billing implementation changes, i.e. toDate later becomes inclusive, this needs to reflect that.
    if (now >= bI.fromDate99454 && now < bI.toDate99454) {
      member.billingInsights['99454'] = bI;
    }
    // 99457 fromDate and toDate are inclusive, as they are always the first and last day of the month.
    if (now >= bI.fromDate99457 && now <= bI.toDate99457) {
      member.billingInsights['99457'] = bI;
    }
    // 99490 fromDate and toDate are inclusive, just like 99457 for timeSpent
    if (now >= ccmBI.fromDate99490 && now <= ccmBI.toDate99490) {
      member.ccmBillingInsights['99490'] = ccmBI;
    }
  });

  const bi99454 = member.billingInsights['99454'];
  const bi99457 = member.billingInsights['99457'];

  const ccmBI99490 = member.ccmBillingInsights['99490'];
  // calculating the member CPT codes, essentially copied exactly from billing insights
  // this field is used in some sections of billingInsights and profile of the member details where memberOne is loaded
  member.cpts = {
    CPT99453: bi99454.CPT99453a,
    CPT99454: bi99454.CPT99454a,
    CPT99457: bi99457.CPT99457a && bi99457.CPT99457c,
    CPT99458: bi99457.CPT99458a && bi99457.CPT99458c,
    CPT99490:
      (ccmBI99490.CPT99490timeSpent &&
        ccmBI99490.CPT99490contactMade) ||
      false,
    CPT99439:
      (ccmBI99490.CPT99439timeSpent &&
        ccmBI99490.CPT99439contactMade) ||
      false,
  };

  return member;
};

export async function processMemberData(member, dateRef) {
  if (!dateRef) {
    dateRef = getYMD();
  } else if (dateRef.length === 7) {
    // if dateRef is in a YYYY-MM format
    dateRef = dateRef + '-01'; // adjust it to the first date
  }

  const ymRef = getYM(dateRef);

  // processing readings and getting billingInsights from backend functions.
  const memberId = member._id;
  const memberProcessedReadings =
    await getCurrentUser().functions.processReadings(member, ymRef);
  memberProcessedReadings._id = memberId;
  return memberProcessedReadings;
}

export function getFlatRawMeasuresHeader() {
  return [
    'Name',
    'DOB',
    'Reading Type',
    'Time',
    'Value',
    'ISO Year Month',
  ];
}

export function getFlatRawMeasures(member, timezone, selectedYm) {
  let bwData = (member.bodyWeightData || []).filter(
    (measure) => !measure.exclude,
  );
  let sbpData = (member.systolicBPData || []).filter(
    (measure) => !measure.exclude,
  );
  let bsData = (member.bloodSugarData || []).filter(
    (measure) => !measure.exclude,
  );
  let cholData = (member.totalCholesterolData || []).filter(
    (measure) => !measure.exclude,
  );
  let spo2Data = (member.spo2Data || []).filter(
    (measure) => !measure.exclude,
  );
  let dbpMap = {};
  let pulseMap = {};
  let hdlMap = {};
  let spo2PiMap = {};
  let spo2PulseMap = {};
  (member.diastolicBPData || []).forEach((measure) => {
    dbpMap[measure.time] = measure.value;
  });
  (member.pulseData || []).forEach((measure) => {
    pulseMap[measure.time] = measure.value;
  });
  (member.hdlData || []).forEach((measure) => {
    hdlMap[measure.time] = measure.value;
  });
  (member.perfusionIndexData || []).forEach((measure) => {
    spo2PiMap[measure.time] = measure.value;
  });
  (member.standalonePulseData || []).forEach((measure) => {
    spo2PulseMap[measure.time] = measure.value;
  });

  if (selectedYm) {
    bwData = bwData.filter(
      (measure) => measure.time.slice(0, 7) === selectedYm,
    );
    sbpData = sbpData.filter(
      (measure) => measure.time.slice(0, 7) === selectedYm,
    );
  }

  const bwFlat = bwData.map((measure) => {
    return [
      member.name,
      dobStr(member.dob),
      'Weight',
      moment(measure.time).tz(timezone).format('MM/DD/YYYY hh:mm A'),
      Math.round(measure.value) + ' lbs',
      measure.time.slice(0, 7),
    ];
  });

  const bsFlat = bsData.map((measure) => {
    return [
      member.name,
      dobStr(member.dob),
      'Blood Sugar',
      moment(measure.time).tz(timezone).format('MM/DD/YYYY hh:mm A'),
      Math.round(measure.value) + ' mg/dL',
      measure.time.slice(0, 7),
    ];
  });

  const sbpFlat = sbpData.map((measure) => {
    return [
      member.name,
      dobStr(member.dob),
      'Blood Pressure',
      moment(measure.time).tz(timezone).format('MM/DD/YYYY hh:mm A'),
      Math.round(measure.value * 10) / 10 +
        '/' +
        Math.round(dbpMap[measure.time] * 10) / 10 +
        ' mmHg ' +
        Math.round(pulseMap[measure.time]) +
        ' bpm',
      measure.time.slice(0, 7),
    ];
  });

  const cholFlat = cholData.map((measure) => {
    return [
      member.name,
      dobStr(member.dob),
      'Total Chol / HDL',
      moment(measure.time).tz(timezone).format('MM/DD/YYYY hh:mm A'),
      Math.round(measure.value * 10) / 10 +
        ' mg/dL ' +
        Math.round(hdlMap[measure.time] * 10) / 10 +
        ' mg/dL',
      measure.time.slice(0, 7),
    ];
  });

  const spo2Flat = spo2Data.map((measure) => {
    return [
      member.name,
      dobStr(member.dob),
      'Pulse Ox',
      moment(measure.time).tz(timezone).format('MM/DD/YYYY hh:mm A'),
      Math.round(measure.value * 10) / 10 +
        ' % spo2 ' +
        (spo2PiMap[measure.time]
          ? Math.round(spo2PiMap[measure.time] * 10) / 10
          : '-') +
        ' % PI ' +
        (spo2PulseMap[measure.time]
          ? Math.round(spo2PulseMap[measure.time])
          : '-') +
        ' bpm',
      measure.time.slice(0, 7),
    ];
  });

  return [...bwFlat, ...sbpFlat, ...bsFlat, ...cholFlat, ...spo2Flat];
}

function getSimpleStats(arrayInput) {
  // mean, sd, CI, numObs, min, max, 25%, 75%
  if (arrayInput.length > 0) {
    const array = arrayInput.sort();
    const mean = array.reduce((a, b) => a + b) / array.length;
    const variance =
      array.reduce((a, b) => (b - mean) ** 2 + a, 0) / array.length;
    const stats = {
      mean: mean,
      variance: variance,
      sd: Math.sqrt(variance),
      ci95: (1.96 * Math.sqrt(variance)) / Math.sqrt(array.length),
      minimum: array[0],
      maximum: array[array.length - 1],
      q1: array[Math.floor(array.length / 4)],
      median: array[Math.floor(array.length / 2)],
      q3: array[Math.floor((array.length * 3) / 4)],
    };
    return stats;
  } else {
    return {
      mean: null,
      variance: null,
      sd: null,
      minimum: null,
      maximum: null,
      q1: null,
      median: null,
      q3: null,
    };
  }
}

export function getMedVitalStats(member) {
  return (member.medications || []).map((x) => {
    const startDate = new Date(x.startYM + '-01').getTime();
    const endDate =
      x.endYM === ''
        ? new Date().getTime()
        : new Date(x.endYM + '-31').getTime();

    x.stats = {
      bodyWeight: getSimpleStats(
        (member.bodyWeightData || [])
          .filter(
            (x) =>
              !x.exclude &&
              new Date(x.time).getTime() >= startDate &&
              new Date(x.time).getTime() <= endDate,
          )
          .map((x) => x.value),
      ),
      systolicBP: getSimpleStats(
        (member.systolicBPData || [])
          .filter(
            (x) =>
              !x.exclude &&
              new Date(x.time).getTime() >= startDate &&
              new Date(x.time).getTime() <= endDate,
          )
          .map((x) => x.value),
      ),
      diastolicBP: getSimpleStats(
        (member.diastolicBPData || [])
          .filter(
            (x) =>
              !x.exclude &&
              new Date(x.time).getTime() >= startDate &&
              new Date(x.time).getTime() <= endDate,
          )
          .map((x) => x.value),
      ),
      bloodSugar: getSimpleStats(
        (member.bloodSugarData || [])
          .filter(
            (x) =>
              !x.exclude &&
              new Date(x.time).getTime() >= startDate &&
              new Date(x.time).getTime() <= endDate,
          )
          .map((x) => x.value),
      ),
    };
    return x;
  });
}
