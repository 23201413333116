import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

function DeviceTypeCheckbox({ type, setType }) {
  return (
    <Form
      style={{
        width: '9em',
        padding: '5px',
        borderTop: '1px solid',
        margin: '1em',
      }}
    >
      <strong style={{ fontSize: '12px' }}>Measurement</strong>
      <Form.Field style={{ padding: '5px' }}>
        <Checkbox
          style={{ fontSize: '12px' }}
          radio
          label="Blood Pressure"
          name="checkboxRadioGroup"
          value="Blood Pressure"
          checked={type === 'Blood Pressure'}
          onChange={(e, data) => setType(data.value)}
        />
        <Checkbox
          style={{ fontSize: '12px' }}
          radio
          label="Weight"
          name="checkboxRadioGroup"
          value="Weight"
          checked={type === 'Weight'}
          onChange={(e, data) => setType(data.value)}
        />
        <Checkbox
          style={{ fontSize: '12px' }}
          radio
          label="Blood Sugar"
          name="checkboxRadioGroup"
          value="Blood Sugar"
          checked={type === 'Blood Sugar'}
          onChange={(e, data) => setType(data.value)}
        />
      </Form.Field>
    </Form>
  );
}

export default DeviceTypeCheckbox;
