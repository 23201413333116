import React, { useState, useEffect, Suspense } from 'react';
import {
  Menu,
  Header,
  Grid,
  Dropdown,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { StitchMembersProvider } from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import {
  useHistory,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  Link,
  useLocation,
} from 'react-router-dom';

import { useStitchAuth } from '../StitchAuth';
import { useQuery } from '../Common/utils';
import { getYMRange } from '../Common/utils';

export default function Reports(props) {
  const pvStart = new Date().getTime();
  const history = useHistory();
  const {
    userCustomData,
    invoices,
    actions: { getOtherUsers },
  } = useStitchAuth();
  const [numOpenInvoices, setNumOpenInvoices] = useState(0);
  const [providerList, setProviderList] = useState([]);
  const viewList = [
    { pathname: 'dashboard', title: 'RPM Activity Dashboard' },
    {
      pathname: 'billing-report',
      title: 'Monthly CPT Billing Reports',
    },
    { pathname: 'billing-stats', title: 'Billing Statistics' },
    { pathname: 'review-record', title: 'Monthly Record Review' },
    { pathname: 'care-calendar', title: 'Care Calendar' },
    { pathname: 'invoice', title: 'Invoices' },
  ];

  let query = useQuery();
  const CareCalendar = React.lazy(() => import('./CareCalendar'));
  const Dashboard = React.lazy(() => import('./Dashboard'));
  const BillingReports = React.lazy(() =>
    import('./BillingReports/BillingReports'),
  );
  const BillingStats = React.lazy(() =>
    import('./BillingStats/BillingStats'),
  );
  const RecordReview = React.lazy(() => import('./RecordReview'));
  const Invoices = React.lazy(() => import('./Invoices'));
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const ymList = getYMRange();

  useEffect(() => {
    if (invoices) {
      setNumOpenInvoices(
        invoices.filter((invoice) => invoice.status === 'open')
          .length,
      );
    }
  }, [invoices]);

  useEffect(() => {
    if (userCustomData) {
      if (providerList.length === 0) {
        getOtherUsers(userCustomData.group_id).then((otherUser) =>
          setProviderList(
            otherUser
              .filter((user) => user.id !== userCustomData.id)
              .map((provider) => {
                return {
                  text: provider.name + ' (' + provider.email + ')',
                  value: provider.id,
                  key: provider.id,
                };
              }),
          ),
        );
      }
    }
  }, [userCustomData, getOtherUsers, providerList.length]);

  if (!userCustomData) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <StitchMembersProvider>
        <div className="app-layout">
          <AppBar />
          <Workspace />
          <div className="channels box">
            <Header
              as="h4"
              style={{ paddingLeft: '1em', color: '#fff' }}
            >
              Reports
            </Header>
            <Menu
              vertical
              secondary
              inverted
              style={{ background: 'transparent' }}
            >
              {viewList.map((view) => (
                <Menu.Item
                  key={view.pathname}
                  active={
                    location.pathname === `/reports/${view.pathname}`
                  }
                >
                  <Link
                    to={{
                      pathname: `${url}/${view.pathname}`,
                      search: `?show=${
                        view.pathname === 'review-record' ||
                        view.pathname === 'care-calendar'
                          ? userCustomData.id
                          : 'all'
                      }&yearMonth=${ymList[0].value}`,
                    }}
                  >
                    {`#  ${view.pathname}`}
                    {view.pathname === 'invoice' && (
                      <sup>{` (${numOpenInvoices})`}</sup>
                    )}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <div className="header-panel box">
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as="h4">
                    {
                      (
                        viewList.find(
                          (view) =>
                            location.pathname ===
                            `/reports/${view.pathname}`,
                        ) || {}
                      ).title
                    }
                    {(location.pathname ===
                      '/reports/billing-report' ||
                      location.pathname ===
                        '/reports/billing-stats' ||
                      location.pathname ===
                        '/reports/review-record') && (
                      <>
                        &nbsp;for&nbsp;
                        <mark>
                          <Dropdown
                            inline
                            options={ymList}
                            scrolling
                            value={query.get('yearMonth')}
                            onChange={(e, d) => {
                              history.push({
                                search: `?show=${
                                  query.get('show') || 'all'
                                }&yearMonth=${d.value}`,
                              });
                            }}
                          />
                        </mark>
                      </>
                    )}
                    <Header.Subheader>
                      <small>
                        last updated at{' '}
                        {new Date(pvStart).toLocaleString()}
                      </small>
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  style={{ padding: '0.5em' }}
                >
                  <small>
                    <span style={{ fontWeight: 300 }}>Show </span>
                    <mark>
                      <Dropdown
                        placeholder="ALL PATIENTS"
                        inline
                        disabled={
                          location.pathname ===
                            '/reports/operational' ||
                          location.pathname ===
                            '/reports/billing-stats' ||
                          location.pathname === '/reports/invoice'
                        }
                        scrolling
                        direction="left"
                        value={query.get('show') || 'all'}
                        options={[
                          {
                            key: 'all',
                            value: 'all',
                            text: 'ALL PATIENTS',
                          },
                          {
                            key: userCustomData.id,
                            value: userCustomData.id,
                            text:
                              'ONLY MY PATIENTS (' +
                              userCustomData.name +
                              ')',
                          },
                          ...providerList,
                        ]}
                        onChange={(e, { value }) => {
                          history.push({
                            search: `?show=${value}&yearMonth=${
                              query.get('yearMonth') ||
                              ymList[0].value
                            }`,
                          });
                        }}
                      />
                    </mark>
                  </small>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path={path}>
                <Redirect to={{ pathname: `${path}/dashboard` }} />
              </Route>
              <Route path={`${path}/dashboard`}>
                <Dashboard />
              </Route>
              <Route path={`${path}/billing-report`}>
                <BillingReports />
              </Route>
              <Route path={`${path}/billing-stats`}>
                <BillingStats />
              </Route>
              <Route path={`${path}/review-record`}>
                <RecordReview />
              </Route>
              <Route path={`${path}/care-calendar`}>
                <CareCalendar />
              </Route>
              <Route path={`${path}/invoice`}>
                <Invoices />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </StitchMembersProvider>
    );
  }
}
