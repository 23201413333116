import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
} from 'semantic-ui-react';
import { useStitchAuth } from './StitchAuth';
import { useHistory, useParams } from 'react-router-dom';
import clientMap from './clientMap.json';
import { getDefaultClientCode } from './Common/whitelabel';

export default function SignupForm() {
  const { client } = useParams();
  const defaultClientCode = getDefaultClientCode();
  const clientCode =
    !client || client === '' || !clientMap[client]
      ? defaultClientCode
      : client;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(false);

  const { actions } = useStitchAuth();

  let history = useHistory();

  const handleSubmit = () => {
    actions
      .handleSignup(email, password)
      .then(() => {
        actions
          .handleEmailPasswordLogin(email, password)
          .then(() =>
            history.replace({
              pathname: '/home',
              search: '?clientCode=' + clientCode,
            }),
          )
          .catch(() => {
            setFormError(true);
          });
      })
      .catch(() => {
        setFormError(true);
      });
  };

  return (
    <div
      style={{
        backgroundImage: 'url("/images/shutterstock_447761335.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            backgroundColor: '#fff',
            padding: '3em',
          }}
        >
          <Header
            as="h2"
            textAlign="center"
            icon
            style={{ lineHeight: '1.5em' }}
          >
            <Image
              src={'/logos/astranalogo.png'}
              style={{ height: '5em', width: 'auto' }}
            />
            <Header.Content style={{ fontWeight: 300 }}>
              Sign-up
              <Header.Subheader
                style={{ lineHeight: '1.5em', paddingTop: '0.5em' }}
              >
                Create your account
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Form size="large" error={formError}>
            <Segment style={{ border: '0', boxShadow: '0 0 0 0' }}>
              <Message error>
                <Message.Content>
                  <Message.Header>Sign-up Error</Message.Header>
                  If you already have an account, please log-in{' '}
                  <a href="/login">here</a>.
                </Message.Content>
              </Message>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                onChange={(event) =>
                  setEmail(
                    event.target.value
                      .toLowerCase()
                      .replace(/\s/g, ''),
                  )
                }
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="New password"
                type="password"
                value={password}
                onChange={(event) =>
                  setPassword(event.target.value.replace(/\s/g, ''))
                }
              />
              <Button
                //color="green"
                primary
                fluid
                size="large"
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
              <p
                style={{ marginTop: '10px', fontWeight: 300 }}
                className="grey text"
              >
                NOTE: Password should be longer than 6 characters.
                <br />
                Already signed up? Log-in{' '}
                <a href={'/login/' + client}>here</a>.
              </p>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
}
