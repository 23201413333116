import { Search, Icon } from 'semantic-ui-react';
import _ from 'lodash';

export default function WorkspaceSearch(props) {

  const searchInputContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  return (
    <div style={searchInputContainerStyle}>
      {props.isOpen ?
        <>
          <Search
            size="mini"
            className="search-box"
            id="workspaceSearch"
            placeholder="Search a workspace by name or code"
            input={{ icon: 'search', iconPosition: 'left' }}
            onSearchChange={_.debounce(props.handleSearchChange, 500, {
              leading: true,
            })}
            results={[]}
            showNoResults={false}
            value={props.searchText}
          />

          <Icon
            name="close"
            size="small"
            className="clear-icon"
            onClick={() => props.setIsOpen(false)}
          />
        </>

        : (
          <Icon
            name="search"
            size="large"
            fitted="true"
            onClick={() => props.setIsOpen(true)}
            className="search-icon"
          />
        )}
    </div>
  );
}