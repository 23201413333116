import React from 'react';
import styled from 'styled-components';
import { useStitchMembers } from '../../StitchMembers';
import 'moment-timezone';
import { needsReviewMeasures } from '../../Common/TodoUtils';
import ClearAlertButton from './ClearAlertButton';

const Wrapper = styled.div`
  border: solid #dc3444 3px;
  border-radius: 3px;
  padding: 12px;
`;

const DescriptionWrapper = styled.div`
  padding-bottom: 16px;
`;

const AlertDescription = styled.span`
  padding-right: 4px;
`;

const AlertWrapper = styled.div`
  padding-bottom: 4px;
`;

export default function AlertBox({ showEcgAlerts }) {
  const { memberOne } = useStitchMembers();

  let alerts = [];
  if (memberOne) {
    alerts = needsReviewMeasures(
      memberOne,
      true,
      null, // no max length b/c we generate all alerts
    );
  }

  if (showEcgAlerts) {
    alerts = alerts.filter(
      (warning) => warning.listTitle.substring(0, 3) === 'ECG',
    );
  } else {
    alerts = alerts.filter(
      (warning) => warning.listTitle.substring(0, 3) !== 'ECG',
    );
  }

  if (alerts.length > 0) {
    return (
      <Wrapper>
        <DescriptionWrapper>
          The following measurements triggered these clinical rules.
          Please review these flagged measurments, then clear the
          alerts.
        </DescriptionWrapper>
        {alerts.map((alert, i) => (
          <AlertWrapper key={i}>
            <AlertDescription>{alert.listTitle}</AlertDescription>
            <ClearAlertButton alertTime={alert.exactTime} />
          </AlertWrapper>
        ))}
      </Wrapper>
    );
  }

  return <></>;
}
