import React, { useState, useEffect } from 'react';
import {
  Grid,
  Form,
  Message,
  MessageHeader,
  Dimmer,
  Loader,
  Divider,
} from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import { useStitchMembers } from '../../StitchMembers';
import { validateEmail } from '../../Common/utils';
import { senderTypes } from '../../Common/Values';
import { platformServices } from '../../Common/Values';
import ContactInfoSection from './ContactInfoSection';
import AddressSection from './AddressSection';
import GenderSection from './GenderSection';
import DevicesSection from './DevicesSection/DevicesSection';
import TitleNameDobSection from './TitleNameDobSection';
import PreferredLanguageSection from './PreferredLanguageSection';
import CongratsMessageModal from './CongratsMessageModal';
import QuickClinicalGuide from './QuickClinicalGuide';
import NoteSection from './NoteSection';
import CareTeamSection from './CareTeamSection';
import FormActions from './FormActions';
import CcmServiceSection from './CcmServicesSection/CcmServiceSection';

export default function FormMember(props) {
  const {
    userCustomData,
    db: { members },
  } = useStitchAuth();
  const { actions } = useStitchMembers();

  const [memberTitle, setMemberTitle] = useState('');
  const [memberName, setMemberName] = useState({
    firstName: '',
    lastName: '',
  });
  const [gender, setGender] = useState('male');
  const [language, setLanguage] = useState('English');

  const [bt004, setBt004] = useState('notNeeded');
  const [bt004Imei, setBt004Imei] = useState('');

  const [bt105, setBt105] = useState('notNeeded');
  const [bt105Imei, setBt105Imei] = useState('');

  const [smig, setSmig] = useState('notNeeded');
  const [smigImei, setSmigImei] = useState('');

  const [tenoviGatewayId, setTenoviGatewayId] = useState('');
  const [tenoviDeviceTypes, setTenoviDeviceTypes] = useState({
    TENOVI_SCALE: {
      enum: 'TENOVI_SCALE',
      state: 'notNeeded',
      gatewayId: '',
    },
    TENOVI_BPM: {
      enum: 'TENOVI_BPM',
      state: 'notNeeded',
      gatewayId: '',
    },
    TENOVI_GLUCOMETER: {
      enum: 'TENOVI_GLUCOMETER',
      state: 'notNeeded',
      gatewayId: '',
    },
    TENOVI_BPM_XL: {
      enum: 'TENOVI_BPM_XL',
      state: 'notNeeded',
      gatewayId: '',
    },
  });

  const [dob, setDOB] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [mrn, setMrn] = useState('');
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [note, setNote] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const [formError, setFormError] = useState(false);
  const [sendEnrollmentText, setSendEnrollmentText] = useState(true);
  const [loading, setLoading] = useState(true);
  const [assignedPhysician, setAssignedPhysician] = useState('');
  const [assignedCareManager, setAssignedCareManger] = useState('');
  const [practiceId, setPracticeId] = useState('');
  const [phoneList, setPhoneList] = useState([]);
  const [phoneDupWarning, setPhoneDupWarning] = useState(false);
  const [phoneInfo, setPhoneInfo] = useState({ type: 'unknown' });
  const [existingMembers, setExistingMembers] = useState([]);
  const [ccmButtonClicked, setCcmButtonClicked] = useState(false);
  const [ccmServiceFields, setCcmServiceFields] = useState({
    caregiver: { name: '', phone: '' },
    methodOfCommunication: 'phone',
    personToContact: 'patient',
    ccmPatientConsent: false,
  });
  
  const [isRequestLimitExceeded, setIsRequestLimitExceeded] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      const members = await actions.loadMembersBasic(
        userCustomData.group_id,
      );
      setExistingMembers(members);
    };
    if (userCustomData) {
      fetchMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData]);

  useEffect(() => {
    if (userCustomData?.superUserAccess) {
      members
        .find(
          {
            owner_id: {
              $in: userCustomData.workspaces.map(
                (user) => user.group_id,
              ),
            },
          },
          { projection: { phone: 1 } },
        )
        .then((res) =>
          setPhoneList(res.map((member) => member.phone)),
        );
    } else if (userCustomData && !userCustomData.superUserAccess) {
      members
        .find(
          { owner_id: userCustomData.group_id },
          { projection: { phone: 1 } },
        )
        .then((res) =>
          setPhoneList(res.map((member) => member.phone)),
        );
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, members]);

  const writeMessage = (memberName) => {
    const message = userCustomData?.smsTemplates[
      language
    ].enrollment.replace(/\$NAME/g, memberName.firstName);
    return message;
  };

  const handleSubmit = (event) => {
    const timestamp = Date.parse(dob);
    const name =
      memberName.firstName.replace(/ /g, '') +
      ' ' +
      memberName.lastName.replace(/ /g, '');
    let checkAlphaNumbericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;

    if (
      memberName.firstName === '' ||
      memberName.lastName === '' ||
      isNaN(timestamp) ||
      phoneNumber.length < 12 ||
      address.line1 === '' ||
      address.city === '' ||
      address.state === '' ||
      address.zipCode.length < 5 ||
      (bt004 === 'connect' && bt004Imei.length < 15) ||
      (bt105 === 'connect' && bt105Imei.length < 15) ||
      (smig === 'connect' && smigImei.length < 7) ||
      ((tenoviDeviceTypes.TENOVI_BPM.state === 'connect' ||
        tenoviDeviceTypes.TENOVI_SCALE.state === 'connect' ||
        tenoviDeviceTypes.TENOVI_GLUCOMETER.state === 'connect' ||
        tenoviDeviceTypes.TENOVI_BPM_XL.state === 'connect') &&
        (tenoviGatewayId.length < 12 ||
          !checkAlphaNumbericRegex.test(tenoviGatewayId))) ||
      (ccmButtonClicked && ccmServiceFields.caregiver.name === '') ||
      (ccmButtonClicked && ccmServiceFields.caregiver.phone === '')
    ) {
      setFormError(true);
    } else {
      setFormError(false);
      let devices = [];
      let tenoviDevices = [];
      if (bt004 !== 'notNeeded') {
        devices.push('BT004');
      }
      if (bt105 !== 'notNeeded') {
        devices.push('BT105');
      }
      if (smig !== 'notNeeded') {
        devices.push('SMIG');
      }

      if (tenoviDeviceTypes.TENOVI_BPM.state !== 'notNeeded') {
        tenoviDevices.push(tenoviDeviceTypes.TENOVI_BPM.enum);
        tenoviDeviceTypes.TENOVI_BPM.gatewayId = tenoviGatewayId;
      }
      if (tenoviDeviceTypes.TENOVI_SCALE.state !== 'notNeeded') {
        tenoviDevices.push(tenoviDeviceTypes.TENOVI_SCALE.enum);
        tenoviDeviceTypes.TENOVI_SCALE.gatewayId = tenoviGatewayId;
      }
      if (tenoviDeviceTypes.TENOVI_GLUCOMETER.state !== 'notNeeded') {
        tenoviDevices.push(tenoviDeviceTypes.TENOVI_GLUCOMETER.enum);
        tenoviDeviceTypes.TENOVI_GLUCOMETER.gatewayId =
          tenoviGatewayId;
      }
      if (tenoviDeviceTypes.TENOVI_BPM_XL.state !== 'notNeeded') {
        tenoviDevices.push(tenoviDeviceTypes.TENOVI_BPM_XL.enum);
        tenoviDeviceTypes.TENOVI_BPM_XL.gatewayId = tenoviGatewayId;
      }

      let messageData = [];
      if (sendEnrollmentText) {
        messageData.push({
          time: new Date().toISOString(),
          value: writeMessage(memberName),
          by: userCustomData.namePublic,
          email: userCustomData.email,
          senderType: senderTypes.AUTO,
          isRead: true,
        });
      }
      const currentDT = new Date().toISOString();
      let member = {
        memberTitle:
          memberTitle || (gender === 'male' ? 'Mr.' : 'Ms.'),
        name: name,
        email: validateEmail(email) ? email : '',
        phone: '+1' + phoneNumber.replace(/(\D+)/g, ''),
        dob:
          new Date(dob).toISOString().substring(0, 11) +
          '00:00:00.000Z',
        enrollmentDate: currentDT,
        disenrolled: false,
        owner_id: userCustomData.group_id, // owner ID
        gender: gender,
        language: language,
        address: address,
        noteData: [
          {
            value: note,
            time: currentDT,
            by: userCustomData.name,
            email: userCustomData.email,
            isRead: true,
          },
        ],
        messageData: messageData,
        optInSMS: true,
        optInEmail: true,
        needsReview: false,
        bodyWeightData: [],
        systolicBPData: [],
        diastolicBPData: [],
        pulseData: [],
        ecgData: [],
        warningLog: [],
        valueInsights: [],
        billingInsights: [],
        diagnosisCodes: [],
        procedureCodes: [],
        assignedPhysician: assignedPhysician,
        assignedCareManager: assignedCareManager,
        practiceId: practiceId,
        additionalInfo: {
          note: note,
          BT004: bt004,
          BT105: bt105,
          BT004Imei: bt004Imei,
          BT105Imei: bt105Imei,
          SMIG: smig,
          SMIGImei: smigImei,
          TENOVI_BPM: tenoviDeviceTypes.TENOVI_BPM,
          TENOVI_SCALE: tenoviDeviceTypes.TENOVI_SCALE,
          TENOVI_GLUCOMETER: tenoviDeviceTypes.TENOVI_GLUCOMETER,
          TENOVI_BPM_XL: tenoviDeviceTypes.TENOVI_BPM_XL,
        },
        devices: devices,
        tenoviDevices: tenoviDevices,
        phoneInfo: phoneInfo,
        mrn: mrn,
        services: [platformServices.RPM],
      };
      // Checking for CCM fields
      if (ccmButtonClicked && ccmServiceFields.ccmPatientConsent) {
        let ccmFields = {
          methodOfCommunication:
            ccmServiceFields.methodOfCommunication,
          personToContact: ccmServiceFields.personToContact,
          caregiver: {
            name: ccmServiceFields.caregiver.name,
            phone:
              '+1' +
              ccmServiceFields.caregiver.phone.replace(/(\D+)/g, ''),
          },
          ccmPatientConsent: ccmServiceFields.ccmPatientConsent,
          disenrolledCcm: false,
          disenrollCcmHist: [],
          enrollmentDateCcm: currentDT,
          ccmBillingInsights: [],
        };
        member = { ...member, ...ccmFields };
        member.services.push(platformServices.CCM);
      }
      actions.enrollMember(member).then((res) => {
        setEnrolled(true);
      });
    }
  };

  const refreshState = (event) => {
    setMemberTitle('');
    setMemberName({ firstName: '', lastName: '' });
    setBt004('notNeeded');
    setBt105('notNeeded');
    setSmig('notNeeded');
    setBt004Imei('');
    setBt105Imei('');
    setSmigImei('');
    setDOB('');
    setGender('male');
    setPhoneNumber('');
    setPhoneDupWarning(false);
    setMrn('');
    setEmail('');
    setAddress({
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    });
    setTenoviDeviceTypes({
      TENOVI_SCALE: {
        enum: 'TENOVI_SCALE',
        state: 'notNeeded',
        gatewayId: '',
      },
      TENOVI_BPM: {
        enum: 'TENOVI_BPM',
        state: 'notNeeded',
        gatewayId: '',
      },
      TENOVI_GLUCOMETER: {
        enum: 'TENOVI_GLUCOMETER',
        state: 'notNeeded',
        gatewayId: '',
      },
      TENOVI_BPM_XL: {
        enum: 'TENOVI_BPM_XL',
        state: 'notNeeded',
        gatewayId: '',
      },
    });
    setTenoviGatewayId('');
    setCcmServiceFields({
      caregiver: { name: '', phone: '' },
      methodOfCommunication: 'phone',
      personToContact: 'patient',
      ccmPatientConsent: false,
    });
    setCcmButtonClicked(false);
    setNote('');
    setEnrolled(false);
    setConfirm(false);
    setFormError(false);
  };

  let memberExists = false;
  existingMembers.forEach((member) => {
    const [mF, mL] = member.name.split(' ');
    const mDob = member.dob.slice(0, 10).split('-');
    const mY = mDob[0];
    const mM = mDob[1];
    const mD = mDob[2];
    const enrDob = dob.split('/');
    const enrY = enrDob[2];
    const enrM = enrDob[0];
    const enrD = enrDob[1];
    if (
      memberName.firstName === mF &&
      memberName.lastName === mL &&
      enrY === mY &&
      enrM === mM &&
      enrD === mD
    ) {
      memberExists = true;
    }
  });

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          style={{
            margin: 0,
            paddingBottom: '5em',
            paddingLeft: '2em',
          }}
        >
          <Grid.Row column="one">
            {props.hasUnpaidInvoice && (
              <Grid.Column
                width={10}
                textAlign="left"
                verticalAlign="middle"
              >
                <Message error>
                  <MessageHeader>Unpaid Invoices</MessageHeader>
                  <p>
                    Our records indicate that you have invoices that
                    are overdue. Please check with your practice
                    administrator.
                  </p>
                </Message>
                <br />
              </Grid.Column>
            )}
            <Grid.Column
              width={10}
              textAlign="left"
              verticalAlign="middle"
            >
              {memberExists && (
                <Message
                  error
                  header="Member Already Exists"
                  content="A member with the same name and birthdate is already enrolled in your org."
                />
              )}
              <Form error={formError}>
                <TitleNameDobSection
                  memberTitle={memberTitle}
                  memberName={memberName}
                  dob={dob}
                  setMemberTitle={setMemberTitle}
                  setMemberName={setMemberName}
                  setDOB={setDOB}
                />
                <GenderSection
                  gender={gender}
                  setGender={setGender}
                />
                <PreferredLanguageSection
                  language={language}
                  setLanguage={setLanguage}
                />
                <ContactInfoSection
                  phoneNumber={phoneNumber}
                  phoneList={phoneList}
                  phoneInfo={phoneInfo}
                  email={email}
                  mrn={mrn}
                  phoneDupWarning={phoneDupWarning}
                  setPhoneNumber={setPhoneNumber}
                  setPhoneInfo={setPhoneInfo}
                  setPhoneDupWarning={setPhoneDupWarning}
                  setEmail={setEmail}
                  setMrn={setMrn}
                />
                <AddressSection
                  address={address}
                  setAddress={setAddress}
                />
                <Divider hidden />
                <DevicesSection
                  formError={formError}
                  setFormError={setFormError}
                  bt004={bt004}
                  bt105={bt105}
                  smig={smig}
                  bt004Imei={bt004Imei}
                  bt105Imei={bt105Imei}
                  smigImei={smigImei}
                  setBt004={setBt004}
                  setBt105={setBt105}
                  setSmig={setSmig}
                  setBt004Imei={setBt004Imei}
                  setBt105Imei={setBt105Imei}
                  setSmigImei={setSmigImei}
                  setSendEnrollmentText={setSendEnrollmentText}
                  tenoviDeviceTypes={tenoviDeviceTypes}
                  setTenoviDeviceTypes={setTenoviDeviceTypes}
                  tenoviGatewayId={tenoviGatewayId}
                  setTenoviGatewayId={setTenoviGatewayId}
                  setIsRequestLimitExceeded={setIsRequestLimitExceeded}
                />
                <CareTeamSection
                  assignedPhysician={assignedPhysician}
                  assignedCareManager={assignedCareManager}
                  practiceId={practiceId}
                  setAssignedPhysician={setAssignedPhysician}
                  setAssignedCareManger={setAssignedCareManger}
                  setPracticeId={setPracticeId}
                  setLoading={setLoading}
                />
                <CcmServiceSection
                  ccmServiceFields={ccmServiceFields}
                  setCcmServiceFields={setCcmServiceFields}
                  ccmButtonClicked={ccmButtonClicked}
                  setCcmButtonClicked={setCcmButtonClicked}
                />
                <Divider hidden />
                <NoteSection note={note} setNote={setNote} />
                <FormActions
                  confirm={confirm}
                  setConfirm={setConfirm}
                  writeMessage={writeMessage}
                  memberName={memberName}
                  sendEnrollmentText={sendEnrollmentText}
                  setSendEnrollmentText={setSendEnrollmentText}
                  memberExists={memberExists}
                  handleSubmit={handleSubmit}
                  ccmButtonClicked={ccmButtonClicked}
                  ccmServiceFields={ccmServiceFields}
                  setCcmServiceFields={setCcmServiceFields}
                  isRequestLimitExceeded={isRequestLimitExceeded}
                />
              </Form>
              <CongratsMessageModal
                enrolled={enrolled}
                refreshState={refreshState}
                memberName={memberName}
              />
            </Grid.Column>
            <QuickClinicalGuide />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
