import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

const DevicesTableHeader = ({
  sortParam,
  setSortParam,
  devices,
  setDevices,
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'imeiAsc') {
              setSortParam('imeiDesc');
              setDevices(
                devices.sort((a, b) => b.imei.localeCompare(a.imei)),
              );
            } else {
              setSortParam('imeiAsc');
              setDevices(
                devices.sort((a, b) => a.imei.localeCompare(b.imei)),
              );
            }
          }}
        >
          Device ID&nbsp;
          {!['imeiAsc', 'imeiDesc'].includes(sortParam) && (
            <Icon name="sort" className="grey text" />
          )}
          {sortParam === 'imeiAsc' && <Icon name="sort up" />}
          {sortParam === 'imeiDesc' && <Icon name="sort down" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'deviceAsc') {
              setSortParam('deviceDesc');
              setDevices(
                devices.sort((a, b) =>
                  b.deviceType.localeCompare(a.deviceType),
                ),
              );
            } else {
              setSortParam('deviceAsc');
              setDevices(
                devices.sort((a, b) =>
                  a.deviceType.localeCompare(b.deviceType),
                ),
              );
            }
          }}
        >
          Device Type&nbsp;
          {!['deviceAsc', 'deviceDesc'].includes(sortParam) && (
            <Icon name="sort" className="grey text" />
          )}
          {sortParam === 'deviceAsc' && <Icon name="sort up" />}
          {sortParam === 'deviceDesc' && <Icon name="sort down" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'signalStrengthAsc') {
              setSortParam('signalStrengthDesc');
              setDevices(
                devices.sort(
                  (a, b) => b.signalStrength - a.signalStrength,
                ),
              );
            } else {
              setSortParam('signalStrengthAsc');
              setDevices(
                devices.sort(
                  (a, b) => a.signalStrength - b.signalStrength,
                ),
              );
            }
          }}
        >
          Signal Strength&nbsp;
          {!['signalStrengthAsc', 'signalStrengthDesc'].includes(
            sortParam,
          ) && <Icon name="sort" className="grey text" />}
          {sortParam === 'signalStrengthAsc' && (
            <Icon name="sort up" />
          )}
          {sortParam === 'signalStrengthDesc' && (
            <Icon name="sort down" />
          )}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'batteryVoltageAsc') {
              setSortParam('batteryVoltageDesc');
              setDevices(
                devices.sort(
                  (a, b) => b.batteryVoltage - a.batteryVoltage,
                ),
              );
            } else {
              setSortParam('batteryVoltageAsc');
              setDevices(
                devices.sort(
                  (a, b) => a.batteryVoltage - b.batteryVoltage,
                ),
              );
            }
          }}
        >
          Battery Voltage&nbsp;
          {!['batteryVoltageAsc', 'batteryVoltageDesc'].includes(
            sortParam,
          ) && <Icon name="sort" className="grey text" />}
          {sortParam === 'batteryVoltageAsc' && (
            <Icon name="sort up" />
          )}
          {sortParam === 'batteryVoltageDesc' && (
            <Icon name="sort down" />
          )}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'lastUsedAsc') {
              setSortParam('lastUsedDesc');
              setDevices(
                devices.sort((a, b) =>
                  b.lastUsed.localeCompare(a.lastUsed),
                ),
              );
            } else {
              setSortParam('lastUsedAsc');
              setDevices(
                devices.sort((a, b) =>
                  a.lastUsed.localeCompare(b.lastUsed),
                ),
              );
            }
          }}
        >
          Last Used Time&nbsp;
          {!['lastUsedAsc', 'lastUsedDesc'].includes(sortParam) && (
            <Icon name="sort" className="grey text" />
          )}
          {sortParam === 'lastUsedAsc' && <Icon name="sort up" />}
          {sortParam === 'lastUsedDesc' && <Icon name="sort down" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'nameAsc') {
              setSortParam('nameDesc');
              setDevices(
                devices.sort((a, b) => b.name.localeCompare(a.name)),
              );
            } else {
              setSortParam('nameAsc');
              setDevices(
                devices.sort((a, b) => a.name.localeCompare(b.name)),
              );
            }
          }}
        >
          Current User&nbsp;
          {!['nameAsc', 'nameDesc'].includes(sortParam) && (
            <Icon name="sort" className="grey text" />
          )}
          {sortParam === 'nameAsc' && <Icon name="sort up" />}
          {sortParam === 'nameDesc' && <Icon name="sort down" />}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default DevicesTableHeader;
