import React from 'react';
import { Form } from 'semantic-ui-react';
import { handlePhoneNumberFormat } from '../../Common/utils';
import { useStitchAuth } from '../../StitchAuth';

const ContactInfoSection = ({
  phoneNumber,
  phoneList,
  phoneInfo,
  email,
  mrn,
  phoneDupWarning,
  setPhoneNumber,
  setPhoneInfo,
  setPhoneDupWarning,
  setEmail,
  setMrn,
}) => {
  const { currentUser } = useStitchAuth();

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    let newPhoneNumber;
    const lastCharIsDeleted = value.length < phoneNumber.length;
    const lastCharIsADash =
      value.length > 0 && value[value.length - 1] === '-';

    if (lastCharIsDeleted && lastCharIsADash) {
      newPhoneNumber = value.slice(0, value.length - 1);
    } else if (lastCharIsDeleted && !lastCharIsADash) {
      newPhoneNumber = value;
    } else {
      newPhoneNumber = handlePhoneNumberFormat(value);
    }
    setPhoneNumber(newPhoneNumber);

    const twilioFormat = '+1' + newPhoneNumber.replace(/(\D+)/g, '');
    if (twilioFormat.length === 12) {
      currentUser.functions
        .lookupPhoneNumber(twilioFormat)
        .then((res) => setPhoneInfo(res));
    }

    const phoneDbFormat = '+1' + newPhoneNumber.replace(/-/g, '');
    if (
      phoneList.filter((phone) => phone === phoneDbFormat).length > 0
    ) {
      setPhoneDupWarning(true);
    } else {
      setPhoneDupWarning(false);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value.trim());
  };

  return (
    <Form.Group widths="equal">
      <Form.Input
        name="phoneNumber"
        required
        label={
          phoneDupWarning ? (
            <label style={{ color: 'red' }}>
              Phone - DUPLICATE PHONE WARNING
            </label>
          ) : (
            <label>Phone - type: {phoneInfo.type}.</label>
          )
        }
        placeholder="000-000-0000"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
      <Form.Input
        name="email"
        label="Email (Patient or Care Giver)"
        placeholder="john.doe@gmail.com"
        value={email}
        onChange={handleEmailChange}
      />
      <Form.Input
        name="mrn"
        label="Medical Record Number (MRN)"
        placeholder="012345"
        value={mrn}
        onChange={(event) => {
          setMrn(event.target.value.trim());
        }}
      />
    </Form.Group>
  );
};

export default ContactInfoSection;
