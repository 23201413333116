import React from 'react';
import { Form } from 'semantic-ui-react';

const GenderSection = ({ gender, setGender }) => {
  const handleGenderChange = (event, { value }) => {
    setGender(value);
  };

  return (
    <Form.Group inline>
      <label>Gender</label>
      <Form.Radio
        label="Male"
        value="male"
        checked={gender === 'male'}
        onChange={handleGenderChange}
      />
      <Form.Radio
        label="Female"
        value="female"
        checked={gender === 'female'}
        onChange={handleGenderChange}
      />
    </Form.Group>
  );
};

export default GenderSection;
