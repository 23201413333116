import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Grid,
  Checkbox,
} from 'semantic-ui-react';
import { useStitchMembers } from '../../StitchMembers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';

function NoteInput(props) {
  const [loading, setLoading] = useState(false);
  const [isMemo, setIsMemo] = useState(false);
  const { memberOne, actions } = useStitchMembers();
  const [quill, setQuill] = useState();
  const toolbarOptions = {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['image', 'code-block'],
      ['emoji'],
    ],
    handlers: {
      emoji: function () {},
    },
  };
  const quillModules = {
    toolbar: toolbarOptions,
    'emoji-toolbar': true,
    'emoji-shortname': true,
  };
  // =============================
  const handleSubmit = async () => {
    setLoading(true);
    const contentRaw = quill.getEditor().root.innerHTML;
    const content = contentRaw.replace(/<\/?span[^>]*>/g, '');
    const contentFinal = content.replaceAll('<p><br></p>', '');
    await actions.updateNote(
      memberOne,
      contentFinal,
      new Date().toISOString(),
      isMemo,
    );
    let range = quill.getEditor().getSelection();
    let position = range ? range.index : 0;
    quill.getEditor().deleteText(0, position);
    setLoading(false);
  };

  return (
    <Grid>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      <Grid.Row columns={2}>
        <Grid.Column width={12}>
          <div style={{ height: '120px', overflowY: 'auto' }}>
            <ReactQuill
              theme="snow"
              ref={(el) => setQuill(el)}
              placeholder="write something here"
              style={{ backgroundColor: '#fff' }}
              modules={quillModules}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid style={{ paddingRight: '2em' }}>
            <Grid.Row>
              <Grid.Column>
                <Checkbox
                  size="mini"
                  label={
                    <label>
                      <small className="grey text thin">
                        This is a memo.
                      </small>
                    </label>
                  }
                  checked={isMemo}
                  onClick={() => setIsMemo(!isMemo)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  style={{
                    backgroundColor: '#1b2a4e',
                    color: '#fff',
                  }}
                  fluid
                  onClick={handleSubmit}
                >
                  Write Notes
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default NoteInput;
