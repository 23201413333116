import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStitchAuth } from '../../StitchAuth';
import { useStitchMembers } from '../../StitchMembers';
import 'moment-timezone';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 30px;
  line-height: 30px;
  color: #112044;
  background: white;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
`;

const Note = styled.span`
  font-style: italic;
  font-size 10px;
  padding-left: 8px;
  text-decoration: underline;
  cursor: pointer;
  color: #112044;
`;

export default function CallNotification() {
  const [caller, setCaller] = useState(null);
  const {
    db: { receivedcalls },
  } = useStitchAuth();
  const { actions } = useStitchMembers();

  useEffect(() => {
    if (receivedcalls) {
      const listenForCall = async () => {
        for await (const change of receivedcalls.watch({
          filter: {
            operationType: 'insert',
          },
        })) {
          // check if the incoming caller's phone number is in members collection
          const { fullDocument } = change;
          const members = await actions.findMembersByPhone(
            fullDocument.phone,
          );
          if (members.length > 0) {
            setCaller({ isMember: true, info: members[0] });
          } else {
            // if no matching members were found, check if the caller is a prospect
            const prospects = await actions.findProspectsByPhone(
              fullDocument.phone,
            );
            if (prospects.length > 0) {
              setCaller({ isMember: false, info: prospects[0] });
            }
          }
        }
      };
      listenForCall();
    }
  }, [receivedcalls, actions]);

  return (
    caller && (
      <Wrapper>
        Incoming Call From {caller.info.name}!
        <Link
          to={`/${
            caller.isMember ? 'member-details' : 'prospect-details'
          }/${caller.info._id}/phone-calls`}
        >
          <Note>phone log</Note>
        </Link>
        <Note
          onClick={() => {
            setCaller(null);
          }}
        >
          dismiss
        </Note>
      </Wrapper>
    )
  );
}
