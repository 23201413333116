import React from 'react';
import { Form } from 'semantic-ui-react';

const TenoviBloodPressureXL = ({
  tenoviDeviceTypes,
  setTenoviDeviceTypes,
}) => {
  return (
    <>
      <Form.Group inline>
        <label>Blood Pressure XL Cuff</label>
        <Form.Radio
          label="Request"
          value="request"
          checked={
            tenoviDeviceTypes.TENOVI_BPM_XL.state === 'request'
          }
          onChange={(e, { value }) =>
            setTenoviDeviceTypes({
              ...tenoviDeviceTypes,
              TENOVI_BPM_XL: {
                ...tenoviDeviceTypes.TENOVI_BPM_XL,
                state: value,
              },
            })
          }
        />
        <Form.Radio
          label="Connect"
          value="connect"
          checked={
            tenoviDeviceTypes.TENOVI_BPM_XL.state === 'connect'
          }
          onChange={(e, { value }) =>
            setTenoviDeviceTypes({
              ...tenoviDeviceTypes,
              TENOVI_BPM_XL: {
                ...tenoviDeviceTypes.TENOVI_BPM_XL,
                state: value,
              },
            })
          }
        />
        <Form.Radio
          label="Not needed"
          value="notNeeded"
          checked={
            tenoviDeviceTypes.TENOVI_BPM_XL.state === 'notNeeded'
          }
          onChange={(e, { value }) =>
            setTenoviDeviceTypes({
              ...tenoviDeviceTypes,
              TENOVI_BPM_XL: {
                ...tenoviDeviceTypes.TENOVI_BPM_XL,
                state: value,
              },
            })
          }
        />
      </Form.Group>
    </>
  );
};

export default TenoviBloodPressureXL;
