import React, { useState, useEffect } from 'react';
import '../../Common/Layout.css';
import { Grid, Dimmer, Loader, Divider } from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import DeviceAssignmentTopSection from './DeviceAssignmentTopSection';
import DevicesListTable from './DevicesListTable/DevicesListTable';
import { getDevices, getMembers } from './DeviceAssignmentUtils';

export default function DeviceAssignment(props) {
  const {
    userCustomData,
    db: { members, registry },
  } = useStitchAuth();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState();
  const [ownerMap, setOwnerMap] = useState({});
  const [stats, setStats] = useState([]);
  const [memberList, setMemberList] = useState();
  const [sortParam, setSortParam] = useState('lastUsedDesc');

  useEffect(() => {
    let isMounted = true;
    const deviceMap = {
      BT004: 'Weight Scale',
      BT105: 'BP Cuff',
      SMIG: 'Glucometer',
    };

    if (userCustomData) {
      getDevices(
        userCustomData.superUserAccess || false,
        userCustomData,
        registry,
        deviceMap,
        isMounted,
        setOwnerMap,
        setDevices,
        setStats,
      );
      getMembers(
        userCustomData.superUserAccess || false,
        members,
        userCustomData,
        isMounted,
        setMemberList,
      );
    }
    return () => {
      isMounted = false;
    };
  }, [userCustomData, members, registry]);

  useEffect(() => {
    if (devices && memberList) {
      setLoading(false);
    }
  }, [devices, memberList]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <Grid style={{ margin: 0, padding: 0 }}>
          <DeviceAssignmentTopSection
            devices={devices}
            stats={stats}
          />
          {devices.length > 0 && (
            <Grid.Row columns={1} style={{ padding: 0 }}>
              <Grid.Column>
                <Divider />
                <DevicesListTable
                  devices={devices}
                  setDevices={setDevices}
                  sortParam={sortParam}
                  setSortParam={setSortParam}
                  memberList={memberList}
                  ownerMap={ownerMap}
                  setOwnerMap={setOwnerMap}
                  registry={registry}
                  setLoading={setLoading}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  }
}
