import React, { useState, useEffect } from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Grid,
  Form,
} from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import { useStitchMembers } from '../../StitchMembers';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import ObjectID from 'bson-objectid';
import { handlePhoneNumberFormat } from '../../Common/utils';

function AlertInput(props) {
  const [loading, setLoading] = useState(false);
  const {
    userCustomData,
    currentUser,
    otherUsers,
    db: { doctoralerts },
  } = useStitchAuth();
  const [phone, setPhone] = useState('000-000-0000');
  const [name, setName] = useState('');
  const [directSecureAddress, setDirectSecureAddress] = useState('');
  const [note, setNote] = useState('');
  const { memberOne, actions } = useStitchMembers();

  useEffect(() => {
    if (memberOne) {
      const physicianLst = otherUsers.filter(
        (user) => user.id === memberOne.assignedPhysician,
      );
      if (physicianLst.length > 0) {
        let newPhoneNumber = handlePhoneNumberFormat(
          (physicianLst[0].phone || '+10000000000').slice(2),
        );
        setPhone(newPhoneNumber);
        setDirectSecureAddress(
          physicianLst[0].directSecureAddress || '',
        );
        setName(physicianLst[0].name || '');
      }
    }
  }, [memberOne, otherUsers]);

  let hasDirectSecureAddress = false;
  if (memberOne) {
    const physicianLst = otherUsers.filter(
      (user) => user.id === memberOne.assignedPhysician,
    );
    if (physicianLst.length > 0) {
      if (physicianLst[0].directSecureAddress) {
        hasDirectSecureAddress = true;
      }
    }
  }

  // +1XXXXXXXXXX
  const formatTwilio = (value) => {
    return '+1' + value.replace(/(\D+)/g, '');
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    let newPhoneNumber;
    const lastCharIsDeleted = value.length < phone.length;
    const lastCharIsADash =
      value.length > 0 && value[value.length - 1] === '-';

    if (lastCharIsDeleted && lastCharIsADash) {
      newPhoneNumber = value.slice(0, value.length - 1);
    } else if (lastCharIsDeleted && !lastCharIsADash) {
      newPhoneNumber = value;
    } else {
      newPhoneNumber = handlePhoneNumberFormat(value);
    }
    setPhone(newPhoneNumber);

    const physicianLst = otherUsers.filter(
      (user) => user.phone === formatTwilio(value),
    );
    if (physicianLst.length > 0) {
      setName(physicianLst[0].name || '');
    } else {
      setName('');
    }
  };

  const handleDirectSecureAddressChange = (event) => {
    const { value } = event.target;
    setDirectSecureAddress(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const k = 3;
    // record the note and patient snapshot in the doctoralerts collection
    const alertDoc = {
      name: memberOne.name,
      phone: memberOne.phone,
      dob: memberOne.dob,
      gender: memberOne.gender,
      bodyWeightData: (memberOne.bodyWeightData || []).slice(
        Math.max((memberOne.bodyWeightData || []).length - k, 1),
      ),
      systolicBPData: (memberOne.systolicBPData || []).slice(
        Math.max((memberOne.systolicBPData || []).length - k, 1),
      ),
      diastolicBPData: (memberOne.diastolicBPData || []).slice(
        Math.max((memberOne.diastolicBPData || []).length - k, 1),
      ),
      pulseData: (memberOne.pulseData || []).slice(
        Math.max((memberOne.pulseData || []).length - k, 1),
      ),
      bloodSugarData: (memberOne.bloodSugarData || []).slice(
        Math.max((memberOne.bloodSugarData || []).length - k, 1),
      ),
      note: note,
      status: 'open',
      by: {
        name: userCustomData.name,
        email: userCustomData.email,
      },
      owner_id: userCustomData.group_id,
    };
    const { insertedId } = await doctoralerts.insertOne(alertDoc);
    const docId = ObjectID(insertedId).toHexString();
    const url = `https://rpm2.ormahealth.com/doctoralerts/${docId}`;

    let contactInfo = formatTwilio(phone);
    if (hasDirectSecureAddress) {
      contactInfo = directSecureAddress;
    }

    // record the note for the member document
    await actions.updateAlert(
      memberOne,
      note,
      docId,
      hasDirectSecureAddress,
      contactInfo,
      name,
      url,
    );

    if (contactInfo === directSecureAddress) {
      const bpBody = alertDoc.systolicBPData
        .reverse()
        .map((bp, idx) => {
          return `${Math.round(bp.value)}/${Math.round(
            alertDoc.diastolicBPData[
              alertDoc.diastolicBPData.length - idx - 1
            ].value,
          )} mmHg ${Math.round(
            alertDoc.pulseData[alertDoc.pulseData.length - idx - 1]
              .value,
          )} bpm at ${new Date(bp.time).toLocaleString()} <br />`;
        });

      const weightBody = alertDoc.bodyWeightData
        .reverse()
        .map((weight) => {
          return `${Math.round(weight.value)} lbs at ${new Date(
            weight.time,
          ).toLocaleString()} <br />`;
        });

      const glucoseBody = alertDoc.bloodSugarData
        .reverse()
        .map((glucose) => {
          return `${Math.round(glucose.value)} mg/dL at ${new Date(
            glucose.time,
          ).toLocaleString()} <br />`;
        });

      const directSecureMsgBody = `"<p>[RPM ALERT]</p><p>Name: ${
        alertDoc.name
      } <br />DoB: ${alertDoc.dob.substring(0, 10)} <br />Phone: ${
        alertDoc.phone
      }</p><p>Escalated By: ${alertDoc.by.name}</p>${
        alertDoc.systolicBPData.length > 0 &&
        `<p>Recent Blood Pressure Readings:<br /> ${bpBody}</p>`
      }${
        alertDoc.bodyWeightData.length > 0 &&
        `<p>Recent Weight Readings:<br /> ${weightBody}</p>`
      }${
        alertDoc.bloodSugarData.length > 0 &&
        `<p>Recent Glucose Readings:<br /> ${glucoseBody}</p>`
      }<p>If you want to see more details about the patient, please log-in to our RPM portal.</p>"`;

      currentUser.functions.sendDirectSecureMessage(
        directSecureAddress,
        '[RPM Alert] Escalated Case Summary',
        directSecureMsgBody,
      );
    } else {
      currentUser.functions.sendTwilioSMS(
        formatTwilio(phone),
        `[RPM Alert] Please click the link to see an escalated case by ${userCustomData.name}: ${url}`,
      );
    }

    setNote('');
    setLoading(false);
  };

  return (
    <Grid>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      <Grid.Row columns={2}>
        <Grid.Column width={12}>
          <Form>
            <Form.TextArea
              name="note"
              label="Note to Doctor"
              placeholder="Additional information about the alert..."
              value={note}
              onChange={(event) => {
                const { value } = event.target;
                setNote(value);
              }}
            />
          </Form>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid style={{ paddingRight: '2em' }}>
            <Grid.Row>
              <Grid.Column>
                <Form size="mini">
                  {hasDirectSecureAddress ? (
                    <Form.Input
                      fluid
                      name="directSecureMessage"
                      label={`Direct Secure Address: Dr. ${name}`}
                      value={directSecureAddress}
                      onChange={handleDirectSecureAddressChange}
                    />
                  ) : (
                    <Form.Input
                      fluid
                      name="phoneNumber"
                      label={`Phone Number: Dr. ${name}`}
                      value={phone}
                      onChange={handlePhoneNumberChange}
                    />
                  )}
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  color="red"
                  floated="right"
                  onClick={handleSubmit}
                  fluid
                >
                  Send Alert
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default AlertInput;
