import React from 'react';
import { Form } from 'semantic-ui-react';

const WeightScaleDevice = ({
  bt004,
  bt004Imei,
  setBt004,
  setBt004Imei,
  setSendEnrollmentText,
}) => {
  return (
    <>
      <Form.Group inline>
        <label>Weight Scale</label>
        <Form.Radio
          label="Request"
          value="request"
          checked={bt004 === 'request'}
          onChange={() => setBt004('request')}
        />
        <Form.Radio
          label="Connect"
          value="connect"
          checked={bt004 === 'connect'}
          onChange={() => {
            setBt004('connect');
            setSendEnrollmentText(false);
          }}
        />
        <Form.Radio
          label="Not needed"
          value="notNeeded"
          checked={bt004 === 'notNeeded'}
          onChange={() => setBt004('notNeeded')}
        />
      </Form.Group>
      {bt004 === 'connect' && (
        <Form.Input
          fluid
          name="bt004Imei"
          label={
            'Weight Scale IMEI (' +
            (15 - bt004Imei.length) +
            ' digit left)'
          }
          placeholder="864606045514923"
          value={bt004Imei}
          onChange={(e) => {
            if (
              e.target.value.length < 16 &&
              !isNaN(e.target.value)
            ) {
              setBt004Imei(e.target.value);
            }
          }}
        />
      )}
    </>
  );
};

export default WeightScaleDevice;
