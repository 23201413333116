import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Message,
} from 'semantic-ui-react';
import { useStitchAuth } from './StitchAuth';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordResetForm = (props) => {
  const [password, setPassword] = useState('');

  const [formError, setFormError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { actions } = useStitchAuth();

  let query = useQuery();
  let token = query.get('token');
  let tokenId = query.get('tokenId');

  const handleSubmit = (event) => {
    actions
      .handleResetPassword(token, tokenId, password)
      .then(() => {
        //history.replace({pathname: '/login'});
        setSuccess(true);
      })
      .catch(() => {
        setFormError(true);
      });
  };

  if (success) {
    return (
      <div
        style={{
          backgroundImage:
            'url("/images/shutterstock_461838712.jpg")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Grid
          textAlign="center"
          style={{
            height: '100vh',
            margin: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
          verticalAlign="middle"
        >
          <Grid.Column
            style={{
              maxWidth: 450,
              backgroundColor: '#fff',
              padding: '3em',
            }}
          >
            <Header as="h2" textAlign="center">
              Password Reset Success!
              <Header.Subheader>
                Please visit the login page and re-login with your new
                password.
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid>
      </div>
    );
  } else {
    return (
      <div
        style={{
          backgroundImage:
            'url("/images/shutterstock_461838712.jpg")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Grid
          textAlign="center"
          style={{
            height: '100vh',
            margin: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
          verticalAlign="middle"
        >
          <Grid.Column
            style={{
              maxWidth: 450,
              backgroundColor: '#fff',
              padding: '3em',
            }}
          >
            <Header as="h2" textAlign="center">
              <Header.Content>Reset Password</Header.Content>
            </Header>
            <Form size="large" error={formError}>
              <Segment style={{ border: '0', boxShadow: '0 0 0 0' }}>
                <Message
                  error
                  header="Invalid Password"
                  content="Password should have at least 6 characters."
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="New Password"
                  type="password"
                  value={password}
                  onChange={(event) =>
                    setPassword(event.target.value.replace(/\s/g, ''))
                  }
                />
                <Button
                  color="green"
                  fluid
                  size="large"
                  onClick={handleSubmit}
                >
                  Change Password
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
};

export default PasswordResetForm;
