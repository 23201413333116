import React from 'react';
import { Form } from 'semantic-ui-react';

const BloodGlucoseDevice = ({
  smig,
  smigImei,
  setSmig,
  setSmigImei,
  setSendEnrollmentText,
}) => {
  return (
    <>
      <Form.Group inline>
        <label>Blood Glucose Meter</label>
        <Form.Radio
          label="Request"
          value="request"
          checked={smig === 'request'}
          onChange={() => setSmig('request')}
        />
        <Form.Radio
          label="Connect"
          value="connect"
          checked={smig === 'connect'}
          onChange={() => {
            setSmig('connect');
            setSendEnrollmentText(false);
          }}
        />
        <Form.Radio
          label="Not needed"
          value="notNeeded"
          checked={smig === 'notNeeded'}
          onChange={() => setSmig('notNeeded')}
        />
      </Form.Group>
      {smig === 'connect' && (
        <Form.Input
          fluid
          name="smigImei"
          label={
            'Blood Glucose Meter (' +
            (7 - smigImei.length) +
            ' digit left)'
          }
          placeholder="2703691"
          value={smigImei}
          onChange={(e) => {
            if (e.target.value.length < 8 && !isNaN(e.target.value)) {
              setSmigImei(e.target.value);
            }
          }}
        />
      )}
    </>
  );
};

export default BloodGlucoseDevice;
