import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { useStitchAuth } from './StitchAuth';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clientMap from './clientMap.json';
import { getDefaultClientCode } from './Common/whitelabel';

const LoginForm = (props) => {
  const { client } = useParams();
  let location = useLocation();
  const defaultClientCode = getDefaultClientCode();
  const clientCode =
    !client || client === '' || !clientMap[client]
      ? defaultClientCode
      : client;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const { actions } = useStitchAuth();

  let history = useHistory();

  const handleSubmit = (event) => {
    setInProcess(true);
    actions
      .handleEmailPasswordLogin(email, password)
      .then(() => {
        let pathname = '/home/all-members';
        if (
          location.state &&
          location.state.from &&
          location.state.from.pathname
        ) {
          pathname = location.state.from.pathname;
        }
        history.replace({
          pathname: pathname,
        });
      })
      .catch(() => {
        setInProcess(false);
        setFormError(true);
      });
  };

  return (
    <div
      style={{
        backgroundImage: 'url("/images/astrana_gradient_2.png")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Dimmer inverted active={inProcess}>
        <Loader />
      </Dimmer>
      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            backgroundColor: '#fff',
            padding: '3em',
          }}
        >
          <Header
            as="h2"
            icon
            textAlign="center"
            style={{ marginBottom: '1.5em' }}
          >
            <Image
              src={'/logos/astranalogo.png'}
              style={{
                margin: '0.7em',
                height: '4em',
                width: 'auto',
              }}
            />
            <Header.Content style={{ fontWeight: 300 }}>
              Log-in to your account
            </Header.Content>
          </Header>

          <Form size="large" error={formError}>
            <Segment style={{ border: '0', boxShadow: '0 0 0 0' }}>
              <Message
                error
                header="Invalid Username or Password"
                content="Please check your username/password again."
              />
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                onChange={(event) =>
                  setEmail(
                    event.target.value
                      .toLowerCase()
                      .replace(/\s/g, ''),
                  )
                }
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(event) =>
                  setPassword(event.target.value.replace(/\s/g, ''))
                }
              />
              <Button
                //style={{backgroundColor: "#335eea", color: "#fff"}}
                //color="green"
                primary
                fluid
                size="large"
                onClick={handleSubmit}
              >
                Log-in
              </Button>
            </Segment>
          </Form>
          <p style={{ marginTop: '2em' }} className="grey text">
            Forgot your password?{' '}
            <a href="/passwordresetsend">Reset your password.</a>
            <br />
            If you don't have an account yet, you can{' '}
            <a href={'/signup/' + client}>sign up</a> right away.
          </p>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default LoginForm;
