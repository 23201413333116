import React, { useState, useEffect } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { StitchMembersProvider } from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import FormMember from './FormMember/FormMember';
import FormProspect from './FormProspect';
import { useParams, useHistory } from 'react-router-dom';
import { useStitchAuth } from '../StitchAuth';

export default function Enrollment() {
  const { invoices } = useStitchAuth();
  const { viewDefault } = useParams();
  const [view, setView] = useState(viewDefault || 'member');
  const [hasUnpaidInvoice, setHasUnpaidInvoice] = useState(false);
  const history = useHistory();

  const handleViewChange = (newView) => {
    setView(newView);
    history.push('/enrollment/' + newView);
  };

  useEffect(() => {
    if (invoices) {
      const daysAgo60 =
        new Date().getTime() / 1000 - 60 * 24 * 60 * 60;
      const unpaid = invoices.some(
        (x) => x.status === 'open' && x.due_date < daysAgo60,
      );
      setHasUnpaidInvoice(unpaid);
    }
  }, [invoices]);

  return (
    <StitchMembersProvider>
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <div className="channels box">
          <Header
            as="h4"
            style={{ paddingLeft: '1em', color: '#fff' }}
          >
            Enrollment
          </Header>
          <Menu
            vertical
            secondary
            inverted
            style={{ background: 'transparent' }}
          >
            <Menu.Item
              active={view === 'member'}
              onClick={() => handleViewChange('member')}
            >
              #&nbsp;&nbsp;enroll-member
            </Menu.Item>
            <Menu.Item
              active={view === 'prospect'}
              onClick={() => handleViewChange('prospect')}
            >
              #&nbsp;&nbsp;enroll-prospect
            </Menu.Item>
          </Menu>
        </div>
        <div className="header-panel box">
          {view === 'member' && (
            <Header as="h4">
              Enroll a Member
              <Header.Subheader>
                Please fill out the form to enroll a member to our RPM
                program.
              </Header.Subheader>
            </Header>
          )}
          {view === 'prospect' && (
            <Header as="h4">
              Enroll a Prospect
              <Header.Subheader>
                Please fill out the form to register a prospective
                member to our RPM program.
              </Header.Subheader>
            </Header>
          )}
        </div>

        <div className="messages">
          {view === 'member' && (
            <FormMember hasUnpaidInvoice={hasUnpaidInvoice} />
          )}
          {view === 'prospect' && <FormProspect />}
        </div>
      </div>
    </StitchMembersProvider>
  );
}
