import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStitchAuth } from '../StitchAuth';

const Bold = styled.span`
  font-weight: 600;
`;

export default function RequestDevices() {
  const { currentUser } = useStitchAuth();
  const [checkoutUrl, setCheckoutUrl] = useState('');
  useEffect(() => {
    if (currentUser) {
      currentUser.functions.createStripeCheckout().then((res) => {
        setCheckoutUrl(res);
      });
    }
  }, [currentUser]);

  let message = (
    <>
      {' '}
      On the <Bold>device order checkout page</Bold>, you can choose
      which devices to order and make your payment.
      <br />
      <br />
      <a target="_blank" rel="noreferrer" href={checkoutUrl}>
        <Button color="blue">
          <Icon name="external alternate" />
          Go to Checkout Page
        </Button>
      </a>
    </>
  );
  if (checkoutUrl === 'Iris Health Subgroup') {
    message = (
      <>If you need to request devices, contact Iris Health.</>
    );
  }

  return checkoutUrl && <div className="messages box">{message}</div>;
}
