import React, { useState, useEffect } from 'react';
import { Grid, Form, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import { useStitchMembers } from '../StitchMembers';
import {
  toTitleCase,
  removeSpecialChars,
  validateEmail,
  handlePhoneNumberFormat,
} from '../Common/utils';

export default function FormProspect(props) {
  const {
    userCustomData,
    currentUser,
    db: { members },
  } = useStitchAuth();
  const { actions } = useStitchMembers();

  const [memberName, setMemberName] = useState({
    firstName: '',
    lastName: '',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enrolled, setEnrolled] = useState(false);
  const [phoneList, setPhoneList] = useState([]);
  const [phoneDupWarning, setPhoneDupWarning] = useState(false);
  const [phoneInfo, setPhoneInfo] = useState({ type: 'unknown' });

  useEffect(() => {
    if (userCustomData?.superUserAccess) {
      members
        .find(
          {
            owner_id: {
              $in: userCustomData.workspaces.map(
                (user) => user.group_id,
              ),
            },
          },
          { projection: { phone: 1 } },
        )
        .then((res) =>
          setPhoneList(res.map((member) => member.phone)),
        );
    } else if (userCustomData && !userCustomData.superUserAccess) {
      members
        .find(
          { owner_id: userCustomData.group_id },
          { projection: { phone: 1 } },
        )
        .then((res) =>
          setPhoneList(res.map((member) => member.phone)),
        );
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, members]);

  const handleNameChange = (event) => {
    const { name, value } = event.target;
    setMemberName({
      ...memberName,
      [name]: removeSpecialChars(toTitleCase(value)).trim(),
    });
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value.trim());
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    let newPhoneNumber;
    const lastCharIsDeleted = value.length < phoneNumber.length;
    const lastCharIsADash =
      value.length > 0 && value[value.length - 1] === '-';

    if (lastCharIsDeleted && lastCharIsADash) {
      newPhoneNumber = value.slice(0, value.length - 1);
    } else if (lastCharIsDeleted && !lastCharIsADash) {
      newPhoneNumber = value;
    } else {
      newPhoneNumber = handlePhoneNumberFormat(value);
    }
    setPhoneNumber(newPhoneNumber);

    const twilioFormat = '+1' + newPhoneNumber.replace(/(\D+)/g, '');
    if (twilioFormat.length === 12) {
      currentUser.functions
        .lookupPhoneNumber(twilioFormat)
        .then((x) => setPhoneInfo(x));
    }

    const phoneDbFormat = '+1' + newPhoneNumber.replace(/-/g, '');
    if (phoneList.filter((x) => x === phoneDbFormat).length > 0) {
      setPhoneDupWarning(true);
    } else {
      setPhoneDupWarning(false);
    }
  };

  const handleSubmit = (event) => {
    const name =
      memberName.firstName.replace(/ /g, '') +
      ' ' +
      memberName.lastName.replace(/ /g, '');

    if (
      memberName.firstName === '' ||
      memberName.lastName === '' ||
      phoneNumber.length < 12
    ) {
      setFormError(true);
    } else {
      const prospect = {
        name: name,
        email: validateEmail(email) ? email : '',
        phone: '+1' + phoneNumber.replace(/(\D+)/g, ''),
        dob: '1970-01-01T00:00:00.000Z',
        enrollmentDate: '1970-01-01T00:00:00.000Z',
        disenrolled: false,
        owner_id: userCustomData.group_id, // owner ID
        gender: 'male',
        language: 'English',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zipCode: '',
        },
        noteData: [],
        messageData: [],
        optInSMS: true,
        optInEmail: true,
        needsReview: false,
        bodyWeightData: [],
        systolicBPData: [],
        diastolicBPData: [],
        pulseData: [],
        ecgData: [],
        warningLog: [],
        valueInsights: [],
        billingInsights: [],
        diagnosisCodes: [],
        procedureCodes: [],
        assignedPhysician: '',
        assignedCareManager: '',
        practiceId: '',
        additionalInfo: {},
        devices: [],
        phoneInfo: phoneInfo,
        prospectiveStatus: 'active',
      };

      actions
        .enrollProspect(prospect)
        .then((res) => setEnrolled(true));
    }
  };

  const refreshState = (event) => {
    setMemberName({ firstName: '', lastName: '' });
    setPhoneNumber('');
    setEmail('');
    setEnrolled(false);
    setFormError(false);
  };

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          style={{
            margin: 0,
            paddingBottom: '5em',
            paddingLeft: '2em',
          }}
        >
          <Grid.Row>
            <Grid.Column
              width={10}
              textAlign="left"
              verticalAlign="middle"
            >
              <Form error={formError}>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                    value={memberName.firstName}
                    onChange={handleNameChange}
                  />
                  <Form.Input
                    required
                    name="lastName"
                    label="Last name"
                    placeholder="Last name"
                    value={memberName.lastName}
                    onChange={handleNameChange}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    name="phoneNumber"
                    required
                    label={
                      phoneDupWarning ? (
                        <label style={{ color: 'red' }}>
                          Phone - DUPLICATE PHONE WARNING
                        </label>
                      ) : (
                        <label>Phone - type: {phoneInfo.type}.</label>
                      )
                    }
                    placeholder="000-000-0000"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                  <Form.Input
                    name="email"
                    label="Email (Patient or Care Giver)"
                    placeholder="john.doe@gmail.com"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Group>
                <Form.Button primary onClick={handleSubmit}>
                  Register the prospective member
                </Form.Button>
              </Form>
              <Modal
                open={enrolled}
                onClose={refreshState}
                basic
                closeIcon
              >
                <Modal.Header>
                  A Prospective Member is created!
                </Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <p>
                      {memberName.firstName +
                        ' ' +
                        memberName.lastName}{' '}
                      is enrolled as a propective member.
                    </p>
                    <p>
                      A new member profile is created on the dashboard
                      page. You can text/call the member through the
                      platform.
                    </p>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
