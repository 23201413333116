import React from 'react';
import { Form } from 'semantic-ui-react';

const RPMConsentSection = ({ confirm, setConfirm }) => {
  return (
    <Form.Checkbox
      label="The member consented to enrolling into the RPM program."
      checked={confirm}
      onChange={() => {
        return confirm ? setConfirm(false) : setConfirm(true);
      }}
    />
  );
};

export default RPMConsentSection;
