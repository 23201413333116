import React from 'react';
import { Form } from 'semantic-ui-react';
import { handlePhoneNumberFormat } from '../../../Common/utils';

const CcmMemberInfoSection = ({
  ccmServiceFields,
  setCcmServiceFields,
}) => {
  const handleCaregiverName = (e, { value }) => {
    setCcmServiceFields({
      ...ccmServiceFields,
      caregiver: { ...ccmServiceFields.caregiver, name: value },
    });
  };

  const handleCaregiverPhone = (e, { value }) => {
    let newPhoneNumber;
    const lastCharIsDeleted =
      value.length < ccmServiceFields.caregiver.phone.length;
    const lastCharIsADash =
      value.length > 0 && value[value.length - 1] === '-';

    if (lastCharIsDeleted && lastCharIsADash) {
      newPhoneNumber = value.slice(0, value.length - 1); //delete the dash
    } else if (lastCharIsDeleted && !lastCharIsADash) {
      newPhoneNumber = value; // set the new value
    } else {
      newPhoneNumber = handlePhoneNumberFormat(value); // always format the new phone number
    }
    setCcmServiceFields({
      ...ccmServiceFields,
      caregiver: {
        ...ccmServiceFields.caregiver,
        phone: newPhoneNumber,
      },
    });
  };

  const handleMethodOfCommunication = (e, { value }) => {
    setCcmServiceFields({
      ...ccmServiceFields,
      methodOfCommunication: value,
    });
  };

  const handlePersonToContact = (e, { value }) => {
    setCcmServiceFields({
      ...ccmServiceFields,
      personToContact: value,
    });
  };

  return (
    <>
      <Form.Group widths="equal">
        <Form.Input
          required={true}
          label="Caregiver's Name"
          value={ccmServiceFields.caregiver.name}
          placeholder="{First Name} {Last Name}"
          onChange={handleCaregiverName}
        />
        <Form.Input
          required={true}
          label="Caregiver's Phone Number"
          value={ccmServiceFields.caregiver.phone}
          placeholder="000-000-0000"
          onChange={handleCaregiverPhone}
        />
      </Form.Group>
      <Form.Group widths={16}>
        <Form.Field content="Preferred Method of Communication" />
        <Form.Radio
          label="Phone"
          value="phone"
          checked={ccmServiceFields.methodOfCommunication === 'phone'}
          onChange={handleMethodOfCommunication}
        />
        <Form.Radio
          label="Email"
          value="email"
          checked={ccmServiceFields.methodOfCommunication === 'email'}
          onChange={handleMethodOfCommunication}
        />
        <Form.Radio
          label="Text"
          value="text"
          checked={ccmServiceFields.methodOfCommunication === 'text'}
          onChange={handleMethodOfCommunication}
        />
      </Form.Group>
      <Form.Group widths={16}>
        <Form.Field content="Preferred Point of Contact" />
        <Form.Radio
          label="Patient"
          value="patient"
          checked={ccmServiceFields.personToContact === 'patient'}
          onChange={handlePersonToContact}
        />
        <Form.Radio
          label="Caregiver"
          value="caregiver"
          checked={ccmServiceFields.personToContact === 'caregiver'}
          onChange={handlePersonToContact}
        />
      </Form.Group>
    </>
  );
};

export default CcmMemberInfoSection;
