import React from 'react';
import { Form } from 'semantic-ui-react';

const CcmConsentSection = ({
  ccmServiceFields,
  setCcmServiceFields,
}) => {
  const handleCcmConsent = (e, data) => {
    setCcmServiceFields({
      ...ccmServiceFields,
      ccmPatientConsent: data.checked,
    });
  };

  return (
    <Form.Checkbox
      label="The member consented to enrolling in the CCM program including sharing copay fees, understanding they can cancel at any time and that information can be shared with other providers"
      required={true}
      checked={ccmServiceFields.ccmPatientConsent}
      onChange={handleCcmConsent}
    />
  );
};

export default CcmConsentSection;
