import clientMap from '../clientMap.json';

export function getDefaultClientCode() {
  let defaultClientCode = 'orma';
  const host = window.location.hostname;
  const path = window.location.pathname;
  const pathTokens = path.split('/');

  Object.entries(clientMap).forEach((x) => {
    const k = x[0];
    const v = x[1];
    if (host.includes(v.emailDomain)) {
      defaultClientCode = k;
    }
  });

  if (
    pathTokens.length > 2 &&
    pathTokens[1] === 'signup' &&
    clientMap[pathTokens[2]]
  ) {
    // if a user signup from a whitelabel partner site,
    // then assign the user to the whitelabel client
    //defaultClientCode = pathTokens[2];
  }

  return defaultClientCode;
}

/*
export function getDefaultClientName() {
  let defaultClientName = 'Orma';
  const host = window.location.hostname;
  Object.entries(clientMap).forEach((x) => {
    const k = x[0];
    const v = x[1];
    if (host.includes(v.emailDomain)) {
      defaultClientName = clientMap[k].companyNameShort;
    }
  });
  return defaultClientName;
}
*/
export function capitalize(s) {
  if (typeof s !== 'string') return '';
  //return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  return s.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
