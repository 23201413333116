import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { useStitchMembers } from '../../StitchMembers';
import styled from 'styled-components';
import EventCreationModal from './EventCreationModal';
import DisplayEvent from './DisplayEvent';

const Space = styled.div`
  margin-bottom: 13px;
`;

// frequencyEnums and generateRepitions are exported as helpers for other components as well
export const frequencyEnums = {
  SINGLE_EVENT: 'SINGLE_EVENT',
  WEEKLY: 'WEEKLY',
  EVERY_OTHER_WEEK: 'EVERY_OTHER_WEEK',
  MONTHLY: 'MONTHLY',
};

export const generateRepitions = (event, dateLimit = null) => {
  //dateLimit is an optional date object to not generate beyond
  const allRepitions = [];
  // Add 23 hours to event start date, so that it makes sense in all local time zones.
  const currTime = new Date(event.start).getTime();
  const currDate = new Date(
    new Date(event.start).setTime(currTime + 23 * 60 * 60 * 1000),
  );
  const numRepeat = event.repitions < 0 ? 500 : event.repitions; // if indefinite, only calculate 500 reps anyway to avoid infinite loop
  for (
    let i = 0;
    i < numRepeat && (!dateLimit || currDate <= dateLimit);
    i++
  ) {
    allRepitions.push(currDate.getTime());
    if (event.frequency === frequencyEnums.SINGLE_EVENT) {
      break;
    } else if (event.frequency === frequencyEnums.WEEKLY) {
      currDate.setDate(currDate.getDate() + 7);
    } else if (event.frequency === frequencyEnums.EVERY_OTHER_WEEK) {
      currDate.setDate(currDate.getDate() + 14);
    } else if (event.frequency === frequencyEnums.MONTHLY) {
      const weekOfMonthNum = Math.ceil(
        new Date(event.start).getDate() / 7,
      );
      const prevMonth = currDate.getMonth();
      currDate.setDate(currDate.getDate() + 7);
      // keep marching until on right week of month of next month. If I overshoot month, it means I need to go back 7 days to be on last day of month I overshot.
      while (
        weekOfMonthNum !== Math.ceil(currDate.getDate() / 7) &&
        currDate.getMonth() - prevMonth < 2
      ) {
        currDate.setDate(currDate.getDate() + 7);
      }
      if (currDate.getMonth() - prevMonth >= 2) {
        currDate.setDate(currDate.getDate() - 7);
      }
    }
  }
  return allRepitions;
};

export default function CareCalendar({ memberId }) {
  const { memberOne, actions } = useStitchMembers();
  const [showNewEventModal, setShowNewEventModal] = useState(false);
  const [eventI, setEventI] = useState(null);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    actions.loadMember(memberId);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  if (loading || !memberOne) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <EventCreationModal
          dbWrite={
            eventToEdit
              ? async (draftEvent) => {
                  await actions.editEvent(
                    memberOne,
                    draftEvent,
                    eventI,
                  );
                }
              : async (draftEvent) => {
                  await actions.addEvent(memberOne, draftEvent);
                }
          }
          setLoading={setLoading}
          eventToEdit={eventToEdit}
          open={showNewEventModal}
          onClose={() => {
            setEventI(null);
            setEventToEdit(null);
            setShowNewEventModal(false);
          }}
        />
        A member's events appear on the{' '}
        <Link target="_blank" to={'/reports/care-calendar'}>
          org care calendar
        </Link>
        . <br />
        Additionally, the member's{' '}
        <Link target="_blank" to={'/home/all-members'}>
          to-do list
        </Link>{' '}
        will display a reminder on days where the member has a
        scheduled event.
        <Space />
        {(memberOne.events || []).map((e, i) => (
          <DisplayEvent
            key={i}
            event={e}
            onEdit={() => {
              setEventI(i);
              setEventToEdit(e);
              setShowNewEventModal(true);
            }}
            deleteEvent={async () => {
              await actions.deleteEvent(memberOne, i);
            }}
            setLoading={setLoading}
          />
        ))}
        <Space />
        <Button
          fluid
          color="blue"
          style={{
            maxWidth: '200px',
          }}
          onClick={() => {
            setShowNewEventModal(true);
          }}
        >
          <Icon name="plus circle" style={{ padding: 0 }} />
          Create New Event
        </Button>
      </div>
    );
  }
}
