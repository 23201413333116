import React from 'react';
import { Table, Header } from 'semantic-ui-react';
import Moment from 'react-moment';
import 'moment-timezone';

// This shows a measurement value in the table.
export default function MeasureCell({
  value,
  value2,
  diff,
  diff2,
  showDecimal,
  timeZone,
  lastDT,
}) {
  return (
    <Table.Cell textAlign="center">
      {value ? (
        <Header as="h5">
          {Math.floor(value)}
          {showDecimal && (
            <span style={{ fontSize: '0.7em' }}>
              .{Math.round((value % 1) * 100)}
            </span>
          )}
          {value2 && (
            <span>
              {` / ${Math.floor(value2)}`}
              {showDecimal && (
                <span style={{ fontSize: '0.7em' }}>
                  .{Math.round((value2 % 1) * 100)}
                </span>
              )}
            </span>
          )}
          &nbsp;&nbsp;
          <span
            style={{ fontSize: '0.7em' }}
            className={diff > 0 ? 'red text' : 'green text'}
          >
            {diff > 0 ? `+${diff}` : diff}
            {diff2 !== undefined && (
              <span className="black text">
                {' / '}
                <span
                  className={diff2 > 0 ? 'red text' : 'green text'}
                >
                  {diff2 > 0 ? `+${diff2}` : `${diff2}`}
                </span>
              </span>
            )}
          </span>
          <Header.Subheader>
            <span style={{ fontSize: '0.7em' }}>
              <Moment
                format="M/D/YY H:mm"
                tz={timeZone}
                date={lastDT}
              />
            </span>
          </Header.Subheader>
        </Header>
      ) : (
        <span>.</span>
      )}
    </Table.Cell>
  );
}
