import React, { useState, useEffect } from 'react';
import { Table, Header, Loader, Dimmer } from 'semantic-ui-react';
import ErrorBoundary from 'react-error-boundary';
import 'moment-timezone';
import { useStitchMembers } from '../StitchMembers';
import { useHistory, Link } from 'react-router-dom';
import { useStitchAuth } from '../StitchAuth';
import { getYM } from '../Common/analytics';
import _ from 'lodash';
import { toDomesticPhoneFormat } from '../Common/utils';

function formatMsg(msg) {
  const msgPrintable = (msg || '')
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, ' ');
  let out = msgPrintable.substring(0, 50);
  if (msgPrintable.length > 50) {
    out += '...';
  }
  return out;
}

export default function ContentTable(props) {
  const { userCustomData } = useStitchAuth();
  const { prospectAll, actions } = useStitchMembers();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (userCustomData) {
      setLoading(true);
      actions.reset();
      actions.loadProspects(getYM(), userCustomData.group_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData]);

  useEffect(() => {
    if (prospectAll) {
      setLoading(false);
    }
  }, [prospectAll]);

  const handleRowClick = (_id) => {
    history.push({
      pathname: `/prospect-details/${_id}/text-messages`,
    });
  };

  const latestNote = (noteData) => {
    const lastNoteItem = noteData
      .filter((note) => !note.isDeleted)
      .slice(-1);
    return lastNoteItem[0];
  };

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <ErrorBoundary>
        <Table size="small" selectable basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Last Message</Table.HeaderCell>
              <Table.HeaderCell>Last Note</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(prospectAll, (d, index) => (
              <Table.Row
                key={d._id}
                onClick={() => handleRowClick(d._id)}
              >
                <Table.Cell>
                  <Header as="h5">
                    <Link
                      to={{
                        pathname: `/prospect-details/${d._id}/text-messages`,
                      }}
                    >
                      {d.name}
                    </Link>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  {toDomesticPhoneFormat(d.phone)}
                </Table.Cell>
                <Table.Cell>{d.email}</Table.Cell>
                <Table.Cell>
                  {d.messageData.length > 0 && (
                    <span
                      className={
                        d.messageData[d.messageData.length - 1].isRead
                          ? ''
                          : 'red text'
                      }
                    >
                      {formatMsg(
                        d.messageData[d.messageData.length - 1].value,
                      )}
                      <br />
                      <small>
                        {d.messageData[d.messageData.length - 1].time}
                      </small>
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {d.noteData.filter((note) => !note.isDeleted)
                    .length > 0 && (
                    <span>
                      {formatMsg(latestNote(d.noteData).value)}
                      <br />
                      <small>{latestNote(d.noteData).time}</small>
                    </span>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ErrorBoundary>
    );
  }
}
