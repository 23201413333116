import React, { useState } from 'react';
import { Icon, Header, Segment, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { frequencyEnums, generateRepitions } from './CareCalendar';
import moment from 'moment';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Italic = styled.div`
  font-style: italic;
`;

export default function DisplayEvent({
  event,
  onEdit,
  deleteEvent,
  setLoading,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const singleEvents = generateRepitions(event);

  // building blocks of sentence summary
  const fullStartDate = moment(event.start)
    .utc()
    .format('dddd, MMMM Do, YYYY');
  const dayOfWeek = moment(event.start).utc().format('dddd');
  let weekOfMonth = '';
  const weekOfMonthNum = Math.ceil(moment(event.start).date() / 7);
  if (weekOfMonthNum === 1) {
    weekOfMonth = '1st';
  } else if (weekOfMonthNum === 2) {
    weekOfMonth = '2nd';
  } else if (weekOfMonthNum === 3) {
    weekOfMonth = '3rd';
  } else if (weekOfMonthNum === 4) {
    weekOfMonth = '4th';
  } else {
    weekOfMonth = 'last';
  }
  const fullEndDate = moment(singleEvents[singleEvents.length - 1])
    .utc()
    .format('dddd, MMMM Do, YYYY');

  let sentenceSummary = '';
  // single event
  if (event.frequency === frequencyEnums.SINGLE_EVENT) {
    sentenceSummary = `${fullStartDate} (single event)`;
  } else {
    let repeatPhrase = `${weekOfMonth} ${dayOfWeek} of each month`;
    if (event.frequency === frequencyEnums.WEEKLY) {
      repeatPhrase = `each ${dayOfWeek}`;
    } else if (event.frequency === frequencyEnums.EVERY_OTHER_WEEK) {
      repeatPhrase = `every other ${dayOfWeek}`;
    }
    sentenceSummary = `Repeats the ${repeatPhrase} starting ${fullStartDate}`;
    // finite repitions
    if (event.repitions > 0) {
      sentenceSummary += `, ending ${fullEndDate} (${event.repitions} occurences)`;
    }
  }

  let dateSummary = `${moment(singleEvents[0]).format('M/D/YY')}`;
  if (event.repitions < 0 || singleEvents.length <= 10) {
    for (let i = 1; i < Math.min(singleEvents.length, 10); i++) {
      dateSummary += `, ${moment(singleEvents[i]).format('M/D')}`;
    }
  } else {
    dateSummary += `, ${moment(singleEvents[1]).format(
      'M/D',
    )}, ${moment(singleEvents[2]).format('M/D')}, ${moment(
      singleEvents[3],
    ).format('M/D')}, ${moment(singleEvents[4]).format(
      'M/D',
    )}, ${moment(singleEvents[5]).format('M/D')}, ${moment(
      singleEvents[6],
    ).format('M/D')} . . . ${moment(
      singleEvents[singleEvents.length - 3],
    ).format('M/D')}, ${moment(
      singleEvents[singleEvents.length - 2],
    ).format('M/D')}, ${moment(
      singleEvents[singleEvents.length - 1],
    ).format('M/D/YY')}, `;
  }
  if (event.repitions < 0 && singleEvents.length > 10) {
    dateSummary += ' . . .';
  }

  return (
    <>
      <DeleteConfirmationModal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        event={event}
        deleteEvent={deleteEvent}
        setLoading={setLoading}
      />
      <Segment padded>
        <ContentWrapper>
          <div>
            <Header size="medium">{event.name}</Header>
            {sentenceSummary}
            <Italic>{dateSummary}</Italic>
          </div>
          <div>
            <Button
              circular
              icon
              basic
              onClick={() => {
                onEdit();
              }}
            >
              <Icon name="edit" />
            </Button>
            <Button
              circular
              icon
              basic
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <Icon name="trash" />
            </Button>
          </div>
        </ContentWrapper>
      </Segment>
    </>
  );
}
