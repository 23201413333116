import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
//import { members, pageviews, registry, ecgs } from "./../stitch";
import _ from 'lodash';
import {
  getYM,
  processMemberData,
  setCurrBillingInsights,
} from './Common/analytics';
import { daysTillEvent } from './Common/TodoUtils';
import ObjectID from 'bson-objectid';
import { useStitchAuth } from './StitchAuth';
import { senderTypes } from './Common/Values';
import { activeMeds, activeMemos, isDemoAccountMember } from './Common/memberUtils';
// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchMembersContext = createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchMembers() {
  const context = React.useContext(StitchMembersContext);
  if (!context) {
    throw new Error(
      `useStitchMembers must be used within a StitchMembersProvider`,
    );
  }
  return context;
}

export function StitchMembersProvider(props) {
  const {
    currentUser,
    userCustomData,
    db: {
      members,
      prospects,
      pageviews,
      registry,
      ecgs,
      twiliocalls,
      receivedcalls,
      tenovidevices,
    },
  } = useStitchAuth();
  const [memberAll, setMemberAll] = useState();
  const [nTot, setNTot] = useState(0);
  const nLimit = 50;
  const [prospectAll, setProspectAll] = useState();
  const [memberOne, setMemberOne] = useState();
  const [prospectOne, setProspectOne] = useState();
  const [memberOnePv, setMemberOnePv] = useState();
  const [opInsights, setOpInsights] = useState();

  // We useMemo to improve performance by eliminating some re-renders
  const memberInfo = React.useMemo(() => {
    const reset = () => {
      setMemberAll(null);
      setProspectAll(null);
      setMemberOne(null);
      setProspectOne(null);
      setOpInsights(null);
    };

    const buildTenoviDevices = async (memberId) => {
      return await tenovidevices.find({
        memberId: memberId,
        // only want devices that have not been deleted or unlinked.
        deleted: null,
        unlinked: null,
      });
    };

    const buildMemberDevices = async (memberId) => {
      // the db returns device info in array
      const deviceRegistryArr = await registry.find({
        memberId: ObjectID(memberId),
      });
      let deviceRegistry = {};
      let devices = [];
      if (deviceRegistryArr.length > 0) {
        // break down the array into an object where the keys are the device
        deviceRegistry = _.keyBy(deviceRegistryArr, 'device');
        // get array of just the devices
        devices = deviceRegistryArr.map(
          (memberDevice) => memberDevice['device'],
        );
      }
      return { deviceRegistry, devices };
    };

    const loadMember = async (memberId) => {
      const fetchMemberBaseData = async () => {
        let memberData =
          (await members.findOne({
            _id: ObjectID(memberId),
          })) || {};
        if (memberData) {
          memberData._id = ObjectID(memberData._id).toHexString();
          // querying twilio calls and received calls to find this member's calls
          const [phoneCalls, received] = await Promise.all([
            twiliocalls.find({
              'call_info.to': memberData.phone,
            }),
            receivedcalls.find({
              phone: memberData.phone,
            }),
          ]);
          memberData.phoneCalls = phoneCalls.concat(received);
        }
        return memberData;
      };

      if (memberId) {
        const [memberData, deviceInfo, tenoviDevices] =
          await Promise.all([
            fetchMemberBaseData(),
            buildMemberDevices(memberId),
            buildTenoviDevices(memberId),
          ]);
        memberData.devices = deviceInfo.devices;
        memberData.deviceRegistry = deviceInfo.deviceRegistry;
        memberData.tenoviDevicesStrings =
          memberData?.tenoviDevices || [];
        memberData.tenoviDevices = tenoviDevices;
        const memberDataFinal = setCurrBillingInsights(
          memberData,
          userCustomData.timeZone,
        );
        setMemberOne(memberDataFinal);
      }
    };

    const loadProspect = async (prospectId) => {
      if (prospectId) {
        let prospectData = await prospects.findOne({
          _id: ObjectID(prospectId),
        });
        if (prospectData) {
          prospectData._id = ObjectID(prospectData._id).toHexString();
          // querying twilio calls and received calls to find this prospect's calls
          const phoneCalls = await twiliocalls.find({
            'call_info.to': prospectData.phone,
          });
          const received = await receivedcalls.find({
            phone: prospectData.phone,
          });
          prospectData.phoneCalls = phoneCalls.concat(received);
          setProspectOne(prospectData);
        }
      }
    };

    const loadMembers = async (
      ymRef = getYM(),
      groupId,
      query = { disenrolled: false },
      sortOption = { dataReceivedDT: -1 },
      pageNum = 0,
    ) => {
      const projection = {
        disenrolled: 1,
        name: 1,
        phone: 1,
        dob: 1,
        gender: 1,
        enrollmentDate: 1,
        lastActiveDate: 1,
        deviceReceiptDate: 1,
        bodyWeight: 1,
        bodyWeightLastDT: 1,
        bodyWeightDiff: 1,
        systolicBP: 1,
        systolicBPLastDT: 1,
        systolicBPDiff: 1,
        diastolicBP: 1,
        diastolicBPDiff: 1,
        pulse: 1,
        pulseDiff: 1,
        pulseLastDT: 1,
        ecg: 1,
        ecgLastDT: 1,
        bloodSugar: 1,
        bloodSugarLastDT: 1,
        bloodSugarDiff: 1,
        step: 1,
        stepLastDT: 1,
        stepDiff: 1,
        spo2: 1,
        spo2LastDT: 1,
        spo2Diff: 1,
        pef: 1,
        pefLastDT: 1,
        pefDiff: 1,
        cpts: 1,
        activeMemos: 1,
        measureFreq: 1,
        warningLog: 1,
        numUnreadMessages: 1,
        assignedPhysician: 1,
        assignedCareManager: 1,
        dataReceivedDT: 1,
        diagnosisCodes: 1,
        practiceId: 1,
        missedCall: 1,
        devices: 1,
        kardiaId: 1,
        timeSpent: 1,
        optInSMS: 1,
        email: 1,
        events: 1,
        liveBilling: 1,
        services: 1,
        careplans: 1,
        memberTitle: 1,
      };
      const owner_id = groupId || userCustomData.group_id;
      let memberDataDoc =
        await currentUser.functions.fetchMembersWithOptions(
          userCustomData.superUserAccess
            ? userCustomData.workspaces.map((x) => x.group_id)
            : [owner_id],
          query,
          projection,
          sortOption,
          nLimit,
          nLimit * pageNum,
        );

      let memberData = memberDataDoc.data;
      let nTot = memberDataDoc.nTot;

      //console.log(memberData.map((x) => x.missedCall));

      for (let i = 0; i < memberData.length; i++) {
        memberData[i] = setCurrBillingInsights(
          memberData[i],
          userCustomData.timeZone,
        );
      }
      // we are not sorting by date measured, because that's how the data is returned to us. This saves some time.

      setMemberAll(memberData);
      setNTot(nTot);
      return memberData;
    };

    const loadProspects = async (ymRef = getYM(), groupId) => {
      let prospectData = [];
      const projection = {
        _id: { $toString: '$_id' },
        owner_id: 1,
        name: 1,
        phone: 1,
        email: 1,
        noteData: 1,
        messageData: 1,
        numUnreadMessages: 1,
      };

      const owner_id = groupId || userCustomData.group_id;

      if (userCustomData.superUserAccess) {
        // NOTE: Super User (e.g. Iris Health) wants a full list view of all the members
        prospectData = await prospects.find(
          {
            owner_id: {
              $in: userCustomData.workspaces.map((x) => x.group_id),
            },
            prospectiveStatus: 'active',
          },
          { projection: projection },
        );
      } else {
        prospectData = await prospects.find(
          { owner_id: owner_id, prospectiveStatus: 'active' },
          { projection: projection },
        );
      }

      // process
      prospectData.forEach((d) => {
        d.lastMessageDate = '1970-01-01';
        if (d.messageData.length > 0) {
          d.lastMessageDate =
            d.messageData[d.messageData.length - 1].time;
        }
      });
      prospectData.sort((a, b) =>
        b.lastMessageDate.localeCompare(a.lastMessageDate),
      );

      //prospectData
      setProspectAll(prospectData);

      return prospectData;
    };

    const loadMembersCC = async (groupId) => {
      // CC: Care Calendar
      const projection = {
        _id: 1,
        owner_id: 1,
        disenrolled: 1,
        name: 1,
        phone: 1,
        dob: 1,
        gender: 1,
        assignedPhysician: 1,
        assignedCareManager: 1,
        // enrollmentDate: 1,
        events: 1,
      };

      const owner_id = groupId || userCustomData.group_id;

      let memberData = await currentUser.functions.fetchMembers(
        userCustomData.superUserAccess // NOTE: Super User (e.g. Iris Health) wants a full list view of all the members
          ? userCustomData.workspaces.map((x) => x.group_id)
          : [owner_id],
        projection,
      );

      _.forEach(memberData, function (d) {
        d._id = ObjectID(d._id).toHexString();
      });

      setMemberAll(
        memberData.filter((member) => !member.disenrolled),
      );

      return memberData;
    };

    const loadMembersDB = async (groupId) => {
      // DB: dashboard
      const projection = {
        _id: 1,
        owner_id: 1,
        disenrolled: 1,
        name: 1,
        phone: 1,
        dob: 1,
        gender: 1,
        enrollmentDate: 1,
        devices: 1,
        address: 1,
        bodyWeight: 1,
        bodyWeightLastDT: 1,
        bodyWeightDiff: 1,
        bodyWeightData: 1,
        systolicBP: 1,
        systolicBPLastDT: 1,
        systolicBPDiff: 1,
        diastolicBP: 1,
        diastolicBPDiff: 1,
        systolicBPData: 1,
        pulse: 1,
        pulseDiff: 1,
        pulseLastDT: 1,
        ecg: 1,
        ecgLastDT: 1,
        ecgData: 1,
        bloodSugar: 1,
        bloodSugarLastDT: 1,
        bloodSugarDiff: 1,
        bloodSugarData: 1,
        lastActiveDate: 1,
        assignedPhysician: 1,
        assignedCareManager: 1,
      };

      const owner_id = groupId || userCustomData.group_id;

      let memberData = await currentUser.functions.fetchMembers(
        userCustomData.superUserAccess // NOTE: Super User (e.g. Iris Health) wants a full list view of all the members
          ? userCustomData.workspaces.map((x) => x.group_id)
          : [owner_id],
        projection,
      );

      _.forEach(memberData, function (d) {
        d._id = ObjectID(d._id).toHexString();
      });

      setMemberAll(
        memberData.filter((member) => !member.disenrolled),
      );

      return memberData;
    };

    const loadMembersBasic = async (groupId) => {
      // DB: dashboard
      const projection = {
        _id: 1,
        dob: 1,
        name: 1,
      };

      const owner_id = groupId || userCustomData.group_id;

      let memberData = await currentUser.functions.fetchMembers(
        userCustomData.superUserAccess // NOTE: Super User (e.g. Iris Health) wants a full list view of all the members
          ? userCustomData.workspaces.map((x) => x.group_id)
          : [owner_id],
        projection,
      );

      _.forEach(memberData, function (d) {
        d._id = ObjectID(d._id).toHexString();
      });

      setMemberAll(
        memberData.filter((member) => !member.disenrolled),
      );

      return memberData;
    };

    // this function is called by billing reports.
    const loadMembersFull = async (ymRef = getYM(), groupId) => {
      const projection = {
        _id: 1,
        disenrolled: 1,
        name: 1,
        dob: 1,
        gender: 1,
        enrollmentDate: 1,
        assignedPhysician: 1,
        assignedCareManager: 1,
        diagnosisCodes: 1,
        practiceId: 1,
        mrn: 1,
        owner_id: 1,
        services: 1,
        enrollmentDateCcm: 1,
        careplans: 1,
        chronicConditions: 1,
      };

      const owner_id = groupId || userCustomData.group_id;
      const showLiveBilling = userCustomData.showLiveBilling || false;
      projection[`liveBilling.${ymRef}`] = 1;
      // use pastBillingReports for months after 2021-09. Not sure if we have past billing reports before this date.
      if (
        !showLiveBilling && // for orgs where showLiveBilling is true, do not fetch past reports, we want to use live only for these orgs.
        new Date(ymRef) >= new Date('2021-09')
      ) {
        projection[`pastBillingReports.${ymRef}.billingInsights`] = 1;
        projection[
          `pastBillingReports.${ymRef}.ccmBillingInsights`
        ] = 1;
        projection[
          `pastBillingReports.${ymRef}.time99453Changed`
        ] = 1;
        projection[
          `pastBillingReports.${ymRef}.time99454Changed`
        ] = 1;
        projection[
          `pastBillingReports.${ymRef}.time99457Changed`
        ] = 1;
        projection[
          `pastBillingReports.${ymRef}.time99458Changed`
        ] = 1;
        projection[
          `pastBillingReports.${ymRef}.time99490Changed`
        ] = 1;
        projection[
          `pastBillingReports.${ymRef}.time99439Changed`
        ] = 1;
      }

      const memberData = await currentUser.functions.fetchMembers(
        userCustomData.superUserAccess
          ? userCustomData.workspaces.map((x) => x.group_id)
          : [owner_id],
        projection,
      );

      _.forEach(memberData, function (d) {
        d._id = ObjectID(d._id).toHexString();
      });

      setMemberAll(memberData);
      return memberData;
    };

    const updateNote = async (
      member,
      noteValue,
      time,
      isMemo,
      source = 'members',
    ) => {
      // NOTE: affects billing
      const noteItem = {
        time: time,
        by: userCustomData.namePublic,
        email: userCustomData.email,
        value: isMemo ? noteValue.replace('memo:', '') : noteValue,
        noteType: isMemo ? 'memo' : 'note',
        noteID: member.noteData.length + 1,
        clearedAt: '',
        clearedBy: '',
        isActive: true,
      };

      member = {
        ...member,
        noteData: [...member.noteData, noteItem],
      };
      member.activeMemos = activeMemos(member.noteData);

      if (source === 'prospects') {
        await prospects.updateOne(
          { _id: ObjectID(member._id) },
          {
            $push: { noteData: noteItem },
            $set: {
              activeMemos: member.activeMemos,
            },
          },
        );
        setProspectOne(member);
      } else {
        await members.updateOne(
          { _id: ObjectID(member._id) },
          {
            $push: { noteData: noteItem },
            $set: {
              activeMemos: member.activeMemos,
            },
          },
        );
        setMemberOne(member);
      }
    };

    const updateAlert = async (
      member,
      noteValue,
      alertId,
      isDirectSecureMsg,
      toContact,
      toName,
      url,
    ) => {
      // NOTE: affects billing
      const noteItem = {
        time: new Date().toISOString(),
        isDirectSecureMsg: isDirectSecureMsg,
        by: userCustomData.namePublic,
        email: userCustomData.email,
        value: noteValue,
        alertId: alertId,
        clearedAt: '',
        to: {
          contactInfo: toContact,
          name: toName,
        },
        url: url,
        status: 'open',
      };
      await members.updateOne(
        { _id: ObjectID(member._id) },
        {
          $push: { doctorAlerts: noteItem },
        },
      );
      setMemberOne({
        ...member,
        doctorAlerts: [...(member.doctorAlerts || []), noteItem],
      });
    };

    const clearMemo = async (
      member,
      noteItem,
      source = 'members',
    ) => {
      // NOTE: affects the dashboard view (projection items)
      const noteData = member.noteData.map((note) => {
        if (note.noteID && note.noteID === noteItem.noteID) {
          note.isActive = false;
          note.clearedAt = new Date().toISOString();
          note.clearedBy = userCustomData.email;
        }
        return note;
      });

      member = { ...member, noteData: noteData };
      member.activeMemos = activeMemos(member.noteData);

      if (source === 'prospects') {
        await prospects.updateOne(
          { _id: ObjectID(member._id) },
          {
            $set: {
              noteData: noteData,
              activeMemos: member.activeMemos,
            },
          },
        );
        setProspectOne(member);
      } else {
        await members.updateOne(
          { _id: ObjectID(member._id) },
          {
            $set: {
              noteData: noteData,
              activeMemos: member.activeMemos,
            },
          },
        );
        setMemberOne(member);
      }
    };

    const handleDeleteNotes = async (
      member,
      noteItem,
      source = 'members',
    ) => {
      const noteData = member.noteData.map((note) => {
        if (note.noteID && note.noteID === noteItem.noteID) {
          note.isDeleted = true;
          note.deletedAt = new Date().toISOString();
          note.deletedBy = userCustomData.email;
          if (note.noteType === 'memo') {
            note.isActive = false;
            note.clearedAt = new Date().toISOString();
            note.clearedBy = userCustomData.email;
          }
        }
        return note;
      });

      member = { ...member, noteData: noteData };
      member.activeMemos = activeMemos(member.noteData);

      if (source === 'prospects') {
        await prospects.updateOne(
          { _id: ObjectID(member._id) },
          {
            $set: {
              noteData: noteData,
              activeMemos: member.activeMemos,
            },
          },
        );
        setProspectOne(member);
      } else {
        await members.updateOne(
          { _id: ObjectID(member._id) },
          {
            $set: {
              noteData: noteData,
              activeMemos: member.activeMemos,
            },
          },
        );
        setMemberOne(member);
      }
    };

    const updateMessage = async (
      member,
      messageValue,
      category,
      source = 'members',
    ) => {
      const msgItem = {
        time: new Date().toISOString(),
        by: userCustomData.namePublic,
        email: userCustomData.email,
        senderType: senderTypes.USER,
        value: messageValue,
        isRead: true,
      };
      if (category) msgItem.templateCategory = category;
      if (source === 'prospects') {
        const memberNew = await prospects.findOneAndUpdate(
          { _id: ObjectID(member._id) },
          { $push: { messageData: msgItem } },
          { returnNewDocument: true },
        );
        setProspectOne(memberNew);
      } else {
        // if source==="members"
        const memberNew = await members.findOneAndUpdate(
          { _id: ObjectID(member._id) },
          { $push: { messageData: msgItem } },
          { returnNewDocument: true },
        );
        setMemberOne(memberNew);
      }
      currentUser.functions.sendTwilioSMS(member.phone, messageValue);
    };

    const updateMessageRead = async (
      memberId,
      source = 'members',
    ) => {
      if (source === 'prospects') {
        const memberNew = await prospects.findOneAndUpdate(
          { _id: ObjectID(memberId) },
          {
            $set: {
              'messageData.$[].isRead': true,
              numUnreadMessages: 0,
            },
          },
          { returnNewDocument: true },
        );
        setProspectOne(memberNew);
      } else {
        const memberNew = await members.findOneAndUpdate(
          { _id: ObjectID(memberId) },
          {
            $set: {
              'messageData.$[].isRead': true,
              numUnreadMessages: 0,
            },
          },
          { returnNewDocument: true },
        );
        setMemberOne(memberNew);
      }
    };

    const updateReviewStatus = async (memberId) => {
      await members.findOneAndUpdate(
        { _id: ObjectID(memberId) },
        { $set: { needsReview: false } },
        { returnNewDocument: true },
      );
      return { ...memberOne, needsReview: false };
    };

    const updateECGData = async (member, ecgData) => {
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: {
            ecgData: ecgData,
            ecg: ecgData[ecgData.length - 1],
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(await processMemberData(memberNew, undefined));
    };

    const loadPageViews = async (
      memberId,
      ymRef = getYM(),
      setPvState = true,
    ) => {
      const userEmailCode = userCustomData.email.replace(
        /[^\w\s]/gi,
        '',
      );
      const pvDoc = await pageviews.findOne({
        memberId: ObjectID(memberId),
        yearMonth: ymRef,
      });
      let pvArray = [];
      if (pvDoc) {
        pvArray = pvDoc.data || [];
        const pvArrayUser = pvArray
          .filter((x) => x.userEmail === userCustomData.email)
          .map((x) => x.end);
        const lastEnd =
          pvArrayUser.length === 0 ? 0 : _.max(pvArrayUser);
        const lastView = (pvDoc.lastView || {})[userEmailCode];
        const allLastViews = pvDoc.lastView || {};
        // This is updating the database
        if (lastView && lastView.start) {
          if (lastView.end > lastEnd) {
            const item = {
              start: Math.max(lastView.start, lastEnd),
              end: lastView.end,
              url: lastView.url,
              userEmail: lastView.userEmail || '',
            };
            await pageviews.updateOne(
              { memberId: ObjectID(memberId), yearMonth: ymRef },
              {
                $push: {
                  data: item,
                },
                $set: {
                  ['lastView.' + userEmailCode]: {
                    start: null,
                  },
                },
              },
            );
          } else {
            // something wrong - clean up the lastView object
            await pageviews.updateOne(
              { memberId: ObjectID(memberId), yearMonth: ymRef },
              {
                $set: {
                  ['lastView.' + userEmailCode]: {
                    start: null,
                  },
                },
              },
            );
          }
        }
        Object.keys(allLastViews).forEach((key) => {
          const view = allLastViews[key];
          if (view.start) {
            pvArray.push(view);
          }
        });
      }
      if (setPvState) {
        setMemberOnePv(pvArray);
      }
      return pvArray;
    };

    const pushPageViews = async (
      memberId,
      url,
      pvStart,
      pvEnd,
      desc,
      ymRef = getYM(),
      setPvState = true,
    ) => {
      const path = url.split('/').slice(0, 5).join('/');
      const targetMember = await members.findOne({
        _id: ObjectID(memberId),
      });
      let timeSpent = 0;
      if (targetMember.timeSpent && targetMember.timeSpent[ymRef]) {
        timeSpent = targetMember.timeSpent[ymRef].value;
      }
      const pvItem = {
        start: pvStart,
        end: pvEnd,
        url: path,
        userEmail: userCustomData.email,
        manualEntry: true,
        comment: desc, // TODO: get what activities this was about...
      };
      await pageviews.updateOne(
        { memberId: ObjectID(memberId), yearMonth: ymRef },
        {
          $push: {
            data: pvItem,
          },
          $set: {
            owner_id: userCustomData.group_id,
          },
        },
        { upsert: true },
      );
      await members.updateOne(
        { _id: ObjectID(memberId) },
        {
          $set: {
            ['timeSpent.' + ymRef]: {
              value: timeSpent + (pvEnd - pvStart),
              time: new Date().toISOString(),
            },
          },
          $push: {
            manualCallsToMember: new Date().toISOString(),
          },
        },
        { upsert: true },
      );

      await loadMember(memberId);
      if (setPvState) {
        setMemberOnePv([...memberOnePv, pvItem]);
      }
      return 0;
    };

    const editPageViews = async (
      memberId,
      pvArray,
      ymRef = getYM(),
      setPvState = true,
    ) => {
      await pageviews.updateOne(
        { memberId: ObjectID(memberId), yearMonth: ymRef },
        {
          $set: { data: pvArray },
        },
      );
      if (setPvState) {
        setMemberOnePv(pvArray);
      }
      await loadMember(memberId);
    };

    const updatePageViews = async (memberId, url, pvStart, pvEnd) => {
      const userEmailCode = userCustomData.email.replace(
        /[^\w\s]/gi,
        '',
      );
      const path = url.split('/').slice(0, 5).join('/');
      const yearMonth = getYM(); // NOTE: always on the current year month
      await pageviews.updateOne(
        { memberId: ObjectID(memberId), yearMonth: yearMonth },
        {
          $set: {
            ['lastView.' + userEmailCode]: {
              start: pvStart,
              end: pvEnd,
              userEmail: userCustomData.email,
              url: path,
            },
            owner_id: userCustomData.group_id,
          },
        },
        { upsert: true },
      );
    };

    const updateTimeSpent = async (
      memberId,
      timeSpent,
      ymRef = getYM(),
    ) => {
      await members.updateOne(
        { _id: ObjectID(memberId) },
        {
          $set: {
            ['timeSpent.' + ymRef]: {
              value: timeSpent,
              time: new Date().toISOString(),
            },
          },
        },
        { upsert: true },
      );
    };

    const updateMember = async (member, newProfile) => {
      if (newProfile.name || newProfile.phone || newProfile.email) {
        await registry.updateMany(
          { memberId: ObjectID(member._id) },
          {
            $set: {
              name: newProfile.name,
              phone: newProfile.phone,
              email: newProfile.email,
            },
          },
        );
      }

      await members.updateOne(
        { _id: ObjectID(member._id) },
        {
          $set: newProfile,
        },
      );
    };

    const updateProspect = async (member, newProfile) => {
      await prospects.updateOne(
        { _id: ObjectID(member._id) },
        {
          $set: newProfile,
        },
      );
    };

    const updateMedications = async (member, medications) => {
      member.medications = activeMeds(medications);
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: {
            medications: member.medications,
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const updateDiagnoses = async (member, diagnoses) => {
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: {
            diagnosisCodes: diagnoses,
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const createCareplan = async (member, careplans) => {
      const memberNew = await members.findOneAndUpdate(
        {
          _id: ObjectID(member._id),
        },
        {
          $set: { careplans: careplans },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const updateCarePlan = async (member, updatedCarePlan) => {
      const memberNew = await members.findOneAndUpdate(
        {
          _id: ObjectID(member._id),
          'careplans.assessmentDate': updatedCarePlan.assessmentDate,
        },
        {
          $set: {
            'careplans.$': updatedCarePlan,
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const addChronicConditions = async (member, conditions) => {
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: {
            chronicConditions: conditions,
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const addMemberAllergies = async (member, allergies) => {
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: {
            allergies: allergies,
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const addCarePlanProgress = async (
      member,
      currentPlan,
      newProgressUpdate,
    ) => {
      const memberNew = await members.findOneAndUpdate(
        {
          _id: ObjectID(member._id),
          'careplans.assessmentDate': currentPlan.assessmentDate,
        },
        {
          $push: { 'careplans.$.progressUpdates': newProgressUpdate },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const updateCareCalendar = async (
      member,
      careCalendar,
      nextC2Checkin,
    ) => {
      await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: {
            careCalendar: careCalendar,
            nextC2Checkin: nextC2Checkin,
          },
        },
        { returnNewDocument: true },
      );
      loadMember(member._id);
    };

    const enrollMember = async (member) => {
      // If new, add the member to the "members" collection
      const { insertedId } = await members.insertOne(member);
      member._id = insertedId;
      // after adding id, processMember
      const memberProcessed = await processMemberData(
        member,
        undefined,
      );
      const derivedFields =
        await currentUser.functions.selectDerivedFields(
          memberProcessed,
        );
      // update member in db with processed derived fields.
      await members.updateOne(
        { _id: insertedId },
        { $set: derivedFields },
      );
      if (member.messageData.length > 0) {
        // Send the enrollment message to the member
        currentUser.functions.sendTwilioSMS(
          member.phone,
          member.messageData[0].value,
        );
      }
      const currentDT = new Date().toISOString();
      for (let i = 0; i < member.devices.length; i++) {
        const device = member.devices[i];

        let registryItem = {
          imei: '',
          name: member.name,
          phone: member.phone,
          email: member.email,
          owner_id: member.owner_id,
          memberId: insertedId,
          device: device,
          startDate: currentDT,
          lastUsed: '',
          batteryVoltage: 0,
          signalStrength: 0,
          status: 'requested',
          statusUpdateTime: currentDT,
          statusHistory: [{ status: 'requested', time: currentDT }],
        };
        if (member.additionalInfo[device] === 'request') {
          await registry.insertOne(registryItem);
        } else if (member.additionalInfo[device] === 'connect') {
          registryItem.status = 'Delivered';
          registryItem.imei = member.additionalInfo[device + 'Imei'];
          await registry.updateOne(
            { imei: registryItem.imei },
            {
              $set: registryItem,
            },
            { upsert: true },
          );
        }
      }

      for (let i = 0; i < member.tenoviDevices.length; i++) {
        const tenoviDevice = member.tenoviDevices[i];
        if (member.additionalInfo[tenoviDevice].state === 'request') {
          await currentUser.functions.orderTenoviDevice(
            insertedId.toString(),
            tenoviDevice,
          ); // TODO: Optimize to only update members tenoviDevices once all have been ordered
          await members.updateOne(
            { _id: insertedId },
            {
              $push: {
                tenoviDevices: tenoviDevice,
              },
            },
          );
        } else if (
          member.additionalInfo[tenoviDevice].state === 'connect'
        ) {
          await currentUser.functions.connectTenoviDevice(
            insertedId.toString(),
            tenoviDevice,
            member.additionalInfo[tenoviDevice].gatewayId,
          );
          await members.updateOne(
            { _id: insertedId },
            {
              $push: {
                tenoviDevices: tenoviDevice,
              },
            },
          );
        }
      }
    };

    const enrollProspect = async (prospect) => {
      const { insertedId } = await prospects.insertOne(prospect);
      prospect._id = insertedId;
      // mimic member initial process of billingInsights with derived fields
      // that way, if prospect is moved over to member, the initial process and billingInsights is done and ready.
      const prospectProcessed = await processMemberData(
        prospect,
        undefined,
      );
      const derivedFields =
        await currentUser.functions.selectDerivedFields(
          prospectProcessed,
        );
      await prospects.updateOne(
        { _id: insertedId },
        { $set: derivedFields },
      );
    };

    const moveProspectToRPM = async (prospect) => {
      prospect._id = ObjectID(prospect._id);
      await prospects.updateOne(
        { _id: ObjectID(prospect._id) },
        { $set: { prospectiveStatus: 'movedToRPM' } },
      );
      prospect.prospectiveStatus = 'movedToRPM';
      prospect.enrollmentDate = new Date().toISOString();
      await enrollMember(prospect);
    };

    const addAdditionalCcmService = async (member, ccmProfile) => {
      const memberNew = await members.findOneAndUpdate(
        {
          _id: ObjectID(member._id),
        },
        {
          $set: {
            caregiver: {
              name: ccmProfile.caregiverName,
              phone: ccmProfile.caregiverPhone,
            },
            methodOfCommunication: ccmProfile.methodOfCommunication,
            ccmPatientConsent: true,
            disenrolledCcm: false,
            enrollmentDateCcm: new Date().toISOString(),
            personToContact: ccmProfile.personToContact,
          },
          $push: {
            services: 'CCM',
            disenrollCcmHist: {
              enrollmentStatus: 'enrolled',
              changedAt: new Date().toISOString(),
              changedBy: userCustomData.email,
            },
          },
        },
        { returnNewDocument: true },
      );
      setMemberOne(memberNew);
    };

    const addDevice = async (member, device, deviceInfo) => {
      // Disabling API order calls for orma demo account by setting status to delivered
      var deviceStatus = isDemoAccountMember(member) ? "delivered": "requested";

      // This function will handle weight scale, bp cuff, and glucometer - NOT kardia ecg.
      const currentDT = new Date().toISOString();
      if (deviceInfo.request) {
        await registry.insertOne({
          imei: '',
          name: member.name,
          phone: member.phone,
          email: member.email,
          owner_id: member.owner_id,
          memberId: ObjectID(member._id),
          device: device,
          startDate: currentDT,
          lastUsed: '',
          batteryVoltage: 0,
          signalStrength: 0,
          status: deviceStatus,
          statusUpdateTime: currentDT,
          statusHistory: [{ status: deviceStatus, time: currentDT }],
        });
      } else {
        await registry.updateOne(
          { imei: deviceInfo.imei },
          {
            $set: {
              imei: deviceInfo.imei,
              name: member.name,
              phone: member.phone,
              email: member.email,
              owner_id: member.owner_id,
              memberId: ObjectID(member._id),
              device: device,
              startDate: currentDT,
              status: 'Delivered',
              statusUpdateTime: currentDT,
              statusHistory: [
                { status: 'Delivered', time: currentDT },
              ],
            },
          },
          { upsert: true },
        );
      }
      await members.updateOne(
        { _id: ObjectID(member._id) },
        {
          $push: {
            devices: device,
          },
        },
      );
      await loadMember(member._id);
    };

    const getkardiaConnectionCode = async (member) => {
      const connectCode =
        await currentUser.functions.getKardiaConnectionCode(
          member._id,
        );
      await loadMember(member.id);
      return connectCode;
    };

    const disconnectKardiaConnection = async (member) => {
      let memberKardiaId = member.kardiaId;
      let result =
        await currentUser.functions.disconnectKardiaConnectionCode(
          member._id,
        );
      if (result.status === 'success') {
        await members.updateOne(
          { _id: ObjectID(member._id) },
          {
            $push: {
              ecgCodeRequests: {
                kardia: memberKardiaId,
                userName: userCustomData.name,
                userEmail: userCustomData.email,
                userId: userCustomData.id,
                timeRequested: new Date().toISOString(), // time of new code request
              },
            },
          },
        );
        await loadMember(member._id);
        return true;
      } else {
        console.log('Error => ', result.status);
        return false;
      }
    };

    const disconnectDevice = async (member, device, reason) => {
      await registry.updateOne(
        {
          imei: member.deviceRegistry[device].imei,
          memberId: ObjectID(member._id),
        },
        {
          $set: {
            name: '',
            phone: '',
            email: '',
            memberId: null,
          },
          $push: {
            exOwners: {
              name: member.name,
              phone: member.phone,
              email: member.email,
              memberId: ObjectID(member._id),
              time: new Date().toISOString(), // time of disconnect
              disconnectReason: reason,
            },
          },
        },
      );
      let devices = member.devices;
      const idx = devices.indexOf(device);
      if (idx > -1) {
        devices.splice(idx, 1);
      }
      await members.updateOne(
        { _id: ObjectID(member._id) },
        {
          $set: {
            devices: devices,
          },
        },
        { returnNewDocument: true },
      );
      await loadMember(member._id);
    };

    const updateDataExclusion = async (member, measure, time) => {
      // NOTE: This affects the billing insights
      const measureData = member[measure].map((measurement) => {
        if (measurement.time === time) {
          measurement.exclude = !measurement.exclude;
        }
        return measurement;
      });
      const memberNew = await processMemberData(
        { ...member, [measure]: measureData },
        undefined,
      );
      delete memberNew['_id'];
      const newMember = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        { $set: memberNew },
        { returnNewDocument: true },
      );
      setMemberOne(newMember);
    };

    const updateMeasurements = async (member, doc) => {
      // NOTE: This process usually happens on the backend when a new measurement arrives.
      // When manually entering measurements, the process should be mimicked as follows:
      let memberNew = { ...member, ...doc.$set };
      Object.keys(doc.$push || {}).forEach((dataType) => {
        if (memberNew[dataType] == null) {
          memberNew[dataType] = [];
        }
        memberNew[dataType].push(doc.$push[dataType]);
      });
      const ruleOutput =
        await currentUser.functions.applyClinicalRules(memberNew, []);
      if (!doc.$set) {
        doc.$set = {};
      }
      doc.$set.clinicalRiskScores = [
        ...ruleOutput.clinicalRiskScores,
      ];
      const memberNew2 = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        doc,
        { returnNewDocument: true },
      );
      setMemberOne(await processMemberData(memberNew2, undefined));
    };

    const getEcgPdf = async (recordingId) => {
      return await ecgs.findOne({ recordingId: recordingId });
    };

    const handleRiskSuggestion = async (member) => {
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: { valueInsights: member.valueInsights },
        },
        { returnNewDocument: true },
      );
      setMemberOne(await processMemberData(memberNew, undefined));
    };

    const assignProvider = async (
      member,
      providerId,
      providerType,
    ) => {
      const memberNew = await members.findOneAndUpdate(
        { _id: ObjectID(member._id) },
        {
          $set: { [providerType]: providerId },
        },
        { returnNewDocument: true },
      );
      setMemberOne(await processMemberData(memberNew, undefined));
    };

    const getRewardAmountThisMonth = async (memberId) => {
      const amount =
        await currentUser.functions.getRewardAmountInMonth(memberId);
      return amount;
    };

    const getRewardAmountThisYear = async (memberId) => {
      const amount =
        await currentUser.functions.getRewardAmountThisYear(memberId);
      return amount;
    };

    const createTangoOrder = async (
      memberId,
      sendEmailAws,
      bulkOrder,
    ) => {
      const res = await currentUser.functions.createTangoOrder(
        memberId,
        sendEmailAws,
        undefined, // signifies that not created by rule.
        bulkOrder,
      );
      await loadMember(memberId);
      return res;
    };

    const createRewardsRule = async (
      memberId,
      ruleType,
      measurementType,
      quantity,
    ) => {
      const res =
        await currentUser.functions.createOrUpdateRewardsRule(
          memberId,
          ruleType,
          measurementType,
          quantity,
        );
      await loadMember(memberId);
      return res;
    };

    const deleteRewardsRule = async (
      memberId,
      ruleType,
      measurementType,
    ) => {
      const res = await currentUser.functions.deleteRewardsRule(
        memberId,
        ruleType,
        measurementType,
      );
      await loadMember(memberId);
      return res;
    };

    const findMembersByPhone = async (phone) => {
      const retMembers = await members.find({
        phone: phone,
      });
      return retMembers;
    };

    const findProspectsByPhone = async (phone) => {
      const retProspects = await prospects.find({
        phone: phone,
      });
      return retProspects;
    };

    const clearAlert = async (member, alertTime) => {
      const warningLogNew = member.warningLog.map((x) => {
        x.needsReview = false;
        return x;
      });
      await members.updateOne(
        {
          _id: ObjectID(member._id),
        },
        {
          $set: {
            warningLog: warningLogNew,
            needsReview: false,
          },
        },
      );
    };

    const addEmDate = async (member, date) => {
      await members.updateOne(
        { _id: ObjectID(member._id) },
        { $addToSet: { emDates: date } },
      );
    };

    const removeEmDate = async (member, date) => {
      await members.updateOne(
        { _id: ObjectID(member._id) },
        { $pull: { emDates: date } },
      );
    };

    const getOrgViewers = async () => {
      const res = await currentUser.functions.fetchOrgViewers(
        userCustomData.superUserAccess // NOTE: Super User (e.g. Iris Health, CVMG) should have viewers of all subOrg members
          ? userCustomData.workspaces.map((x) => x.group_id)
          : [userCustomData.group_id],
      );
      return res;
    };

    const addEvent = async (member, event) => {
      const numDaysTillEvent = daysTillEvent([
        ...(member.events || []),
        event,
      ]);

      await members.updateOne(
        { _id: ObjectID(member._id) },
        {
          $push: { events: event },
          $set: { days_till_event: numDaysTillEvent },
        },
      );

      await loadMember(member._id);
    };

    const editEvent = async (member, newEvent, i) => {
      const setObject = {};

      let eventsTmp = member.events;
      eventsTmp[i] = newEvent;
      const numDaysTillEvent = daysTillEvent(eventsTmp);

      setObject[`events.${i}`] = newEvent;
      setObject['days_till_event'] = numDaysTillEvent;

      await members.updateOne(
        { _id: ObjectID(member._id) },
        { $set: setObject },
      );

      await loadMember(member._id);
    };

    const deleteEvent = async (member, i) => {
      const setObject = {};

      let eventsTmp = member.events.filter((event, j) => i !== j);
      const numDaysTillEvent = daysTillEvent(eventsTmp);
      setObject['events'] = eventsTmp;
      setObject['days_till_event'] = numDaysTillEvent;

      await members.updateOne(
        { _id: ObjectID(member._id) },
        { $set: setObject },
      );

      await loadMember(member._id);
    };

    const value = {
      memberAll,
      nTot,
      nLimit,
      prospectAll,
      memberOne,
      prospectOne,
      memberOnePv,
      opInsights,
      actions: {
        reset,
        loadMember,
        loadProspect,
        loadMembers,
        loadProspects,
        loadMembersDB,
        loadMembersCC,
        loadMembersBasic,
        loadMembersFull,
        updateNote,
        updateAlert,
        clearMemo,
        handleDeleteNotes,
        updateMessage,
        updateMessageRead,
        updateTimeSpent,
        loadPageViews,
        updatePageViews,
        pushPageViews,
        editPageViews,
        updateMedications,
        updateDiagnoses,
        createCareplan,
        updateCarePlan,
        addChronicConditions,
        addMemberAllergies,
        addCarePlanProgress,
        updateCareCalendar,
        enrollMember,
        enrollProspect,
        moveProspectToRPM,
        addAdditionalCcmService,
        addDevice,
        getkardiaConnectionCode,
        disconnectKardiaConnection,
        disconnectDevice,
        updateDataExclusion,
        updateMember,
        updateProspect,
        updateMeasurements,
        updateReviewStatus,
        getEcgPdf,
        updateECGData,
        handleRiskSuggestion,
        assignProvider,
        getRewardAmountThisMonth,
        getRewardAmountThisYear,
        createTangoOrder,
        createRewardsRule,
        deleteRewardsRule,
        findMembersByPhone,
        findProspectsByPhone,
        clearAlert,
        addEmDate,
        removeEmDate,
        getOrgViewers,
        addEvent,
        editEvent,
        deleteEvent,
      },
    };
    return value;
  }, [
    memberAll,
    nTot,
    prospectAll,
    memberOne,
    prospectOne,
    memberOnePv,
    opInsights,
    currentUser,
    userCustomData,
    ecgs,
    members,
    prospects,
    pageviews,
    registry,
    twiliocalls,
    receivedcalls,
    tenovidevices,
  ]);
  return (
    <StitchMembersContext.Provider value={memberInfo}>
      {props.children}
    </StitchMembersContext.Provider>
  );
}

StitchMembersProvider.propTypes = {
  children: PropTypes.element,
};
