import React from 'react';
import './Common/Layout.css';
import { useStitchAuth } from './StitchAuth';
import UserInformation from './Agreement/UserInformation';
import MemberList from './MemberList/MemberList';
import Login from './Login';

export default function Home() {
  const { isLoggedIn, userCustomData } = useStitchAuth();

  if (isLoggedIn) {
    if (userCustomData && !userCustomData.hasBasicInformation) {
      return <UserInformation />;
    } else {
      return <MemberList />;
    }
  } else {
    return <Login />;
  }
}
