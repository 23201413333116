import React, { useState } from 'react';
import { Tab, Menu } from 'semantic-ui-react';
import TemplateCategories from './TemplateCategories';
import DeviceTypeCheckbox from './DeviceTypeCheckbox';

function TemplateBox({ setLoading, toggleTemplate }) {
  const [measureValue, setMeasureValue] = useState('$MEASUREMENT');

  const panes = [
    {
      menuItem: 'Reminder',
      render: () => (
        <TemplateCategories
          key="reminder"
          toggleTemplate={toggleTemplate}
          setTemplateLoading={setLoading}
          measurementType={measureValue}
          templateCategory={'reminders'}
        />
      ),
    },
    {
      menuItem: 'Encourage',
      render: () => (
        <TemplateCategories
          key="encourage"
          toggleTemplate={toggleTemplate}
          setTemplateLoading={setLoading}
          measurementType={measureValue}
          templateCategory={'congratulatory'}
        />
      ),
    },
    {
      menuItem: 'Warning',
      render: () => (
        <TemplateCategories
          key="warning"
          toggleTemplate={toggleTemplate}
          setTemplateLoading={setLoading}
          measurementType={measureValue}
          templateCategory={'warning'}
        />
      ),
    },
    {
      menuItem: 'Check-In',
      render: () => (
        <TemplateCategories
          key="checkin"
          toggleTemplate={toggleTemplate}
          setTemplateLoading={setLoading}
          measurementType={measureValue}
          templateCategory={'checkIn'}
        />
      ),
    },
    {
      menuItem: () => (
        <DeviceTypeCheckbox
          key="devicetypes"
          type={measureValue}
          setType={setMeasureValue}
        />
      ),
    },
  ];

  return (
    <Menu fluid vertical size="tiny">
      <Menu.Item>
        <Tab
          menu={{
            attached: false,
            vertical: true,
            tabular: true,
          }}
          grid={{ paneWidth: 13, tabWidth: 3 }}
          panes={panes}
        />
      </Menu.Item>
    </Menu>
  );
}

export default TemplateBox;
