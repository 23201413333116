import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Grid,
  Icon,
} from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import { useStitchMembers } from '../../StitchMembers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import TemplateBox from './TemplatesBox/TemplateBox';

function MessageInput() {
  const [loading, setLoading] = useState(false);
  const { userCustomData } = useStitchAuth();
  const { memberOne, actions } = useStitchMembers();
  const [quill, setQuill] = useState();
  const [templateVisible, setTemplateVisible] = useState(false);

  const CustomTemplateIcon = () => <Icon name="edit outline" />;

  const toggleTemplate = () => setTemplateVisible(!templateVisible);

  const CustomToolbar = () => (
    <div id="toolbar">
      <button className="ql-emoji" />
      <button className="ql-toggleTemplate">
        <CustomTemplateIcon />
      </button>
    </div>
  );

  const toolbarOptions = {
    container: '#toolbar',
    handlers: {
      toggleTemplate: toggleTemplate,
    },
  };

  const quillModules = {
    toolbar: toolbarOptions,
    'emoji-toolbar': true,
    'emoji-shortname': true,
  };

  const handleSubmit = async () => {
    setLoading(true);
    const contentRaw = quill.getEditor().root.innerHTML;
    const content = contentRaw.replace(/<[^>]+>/g, '');
    await actions.updateMessage(memberOne, content);
    let range = quill.getEditor().getSelection();
    let position = range ? range.index : 0;
    quill.getEditor().deleteText(0, position);
    setLoading(false);
  };

  return (
    <>
      {templateVisible ? (
        <TemplateBox
          toggleTemplate={toggleTemplate}
          setLoading={setLoading}
        />
      ) : null}
      <Grid>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}

        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <div style={{ height: '140px', overflowY: 'auto' }}>
              <CustomToolbar />
              <ReactQuill
                theme="snow"
                ref={(el) => setQuill(el)}
                placeholder="write something here"
                style={{ backgroundColor: '#fff' }}
                modules={quillModules}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <Grid style={{ paddingRight: '2em' }}>
              <Grid.Row>
                <Grid.Column>
                  <small className="grey text thin">
                    A text message will be sent from this RPM
                    platform's number: +1-480-725-1429.
                    <br />
                    {memberOne && !memberOne.optInSMS && (
                      <span style={{ color: '#dc3545' }}>
                        The member opted out of receiving text
                        messages. You cannot send a text message from
                        this number till the member replies "START" to
                        this number.
                      </span>
                    )}
                    {memberOne && !userCustomData.text_usage_consent && (
                      <span style={{ color: '#dc3545' }}>
                        To use this feature, please enable our text
                        messaging capabilities at{' '}
                        <a href="/settings/sms-group">#sms-group</a>
                      </span>
                    )}
                  </small>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {memberOne && userCustomData && (
                    <Button
                      color="yellow"
                      floated="right"
                      onClick={handleSubmit}
                      fluid
                      disabled={
                        !userCustomData.text_usage_consent ||
                        !memberOne.optInSMS
                      }
                    >
                      Send Message
                      {!memberOne.optInSMS && (
                        <small className="red text">
                          {' '}
                          WARNING: The member opted out of receiving
                          text messages.
                        </small>
                      )}
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default MessageInput;
